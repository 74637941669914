<template>
  <div class="step-content">
    <div class="section">
      <cr-highlighted-title :title="'PGA - IBS Data'"/>

      <cr-label :title="'Created by'" :required="true"/>
      <cr-select :options="createdByOptions"
                 :id="'input.s_step_4.s_jc_sc_80'"
                 @click="this.$emit('showRestrictions', 's_step_4.s_jc_sc_80')"
                 @change="this.$emit('validateInput', 's_step_4.s_jc_sc_80')"
                 v-model="form.s_step_4.s_jc_sc_80"/>

      <cr-info :type="'error'"
               :id="'error.s_step_4.s_jc_sc_80'"
               :text="'Field cannot be empty'"/>
    </div>

    <div class="section">
      <cr-highlighted-title :title="'Customer Data'"/>
      <cr-label :title="'Company Name'" :required="true"/>
      <cr-input :id="'input.s_step_4.s_jc_sc_81'"
                @focus="this.$emit('showRestrictions', 's_step_4.s_jc_sc_81')"
                @blur="this.$emit('validateInput', 's_step_4.s_jc_sc_81')"
                v-model="form.s_step_4.s_jc_sc_81"/>

      <cr-info :type="'error'"
               :id="'error.s_step_4.s_jc_sc_81'"
               :text="'Input cannot be empty'"/>

      <cr-label :title="'Location'"/>
      <cr-textarea :id="'input.s_step_4.s_jc_sc_82'"
                   v-model="form.s_step_4.s_jc_sc_82"/>

<!--      <cr-info :type="'error'"-->
<!--               :id="'error.s_step_4.s_jc_sc_82'"-->
<!--               :text="'Input cannot be empty'"/>-->

      <cr-label :title="'Created for'"/>
      <cr-input :id="'input.s_step_4.s_jc_sc_83'"
                @focus="this.$emit('showRestrictions', 's_step_4.s_jc_sc_83')"
                @blur="this.$emit('validateInput', 's_step_4.s_jc_sc_83')"
                v-model="form.s_step_4.s_jc_sc_83"/>

<!--      <cr-info :type="'error'"-->
<!--               :id="'error.s_step_4.s_jc_sc_83'"-->
<!--               :text="'Input cannot be empty'"/>-->

      <cr-label :title="'Email'"/>
      <cr-input :id="'input.s_step_4.s_jc_sc_84'"
                @focus="this.$emit('showRestrictions', 's_step_4.s_jc_sc_84')"
                @blur="this.$emit('validateInput', 's_step_4.s_jc_sc_84')"
                v-model="form.s_step_4.s_jc_sc_84"/>

<!--      <cr-info :type="'error'"-->
<!--               :id="'error.s_step_4.s_jc_sc_84'"-->
<!--               :text="'Input cannot be empty'"/>-->

      <cr-label :title="'Mobile phone'"/>
      <cr-input :id="'input.s_step_4.s_jc_sc_85'"
                @focus="this.$emit('showRestrictions', 's_step_4.s_jc_sc_85')"
                @blur="this.$emit('validateInput', 's_step_4.s_jc_sc_85')"
                v-model="form.s_step_4.s_jc_sc_85"/>

<!--      <cr-info :type="'error'"-->
<!--               :id="'error.s_step_4.s_jc_sc_85'"-->
<!--               :text="'Input cannot be empty'"/>-->

      <cr-label :title="'Date'"/>
      <cr-input :type="'date'" v-model="form.s_step_4.date"/>
    </div>

    <div class="section">
      <cr-highlighted-title :title="'Plant Data'"/>

      <cr-label :title="'Plant / Project Title'"/>
      <cr-textarea :id="'input.s_step_4.s_jc_sc_86'"
                   v-model="form.s_step_4.s_jc_sc_86"/>

<!--      <cr-info :type="'error'"-->
<!--               :id="'error.s_step_4.s_jc_sc_86'"-->
<!--               :text="'Input cannot be empty'"/>-->

      <cr-label :title="'Site of Installation'"/>
      <cr-input :id="'input.s_step_4.s_jc_sc_87'"
                @focus="this.$emit('showRestrictions', 's_step_4.s_jc_sc_87')"
                @blur="this.$emit('validateInput', 's_step_4.s_jc_sc_87')"
                v-model="form.s_step_4.s_jc_sc_87"/>

<!--      <cr-info :type="'error'"-->
<!--               :id="'error.s_step_4.s_jc_sc_87'"-->
<!--               :text="'Input cannot be empty'"/>-->
    </div>
  </div>
</template>

<style scoped lang="scss">
.step-content {
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  padding: 20px 20px 100px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.04);

  .section:not(:last-child) {
    border-bottom: 1px solid #CFD8DC;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
}
</style>

<script>
import CrHighlightedTitle from "@/components/CrHighlightedTitle";
import CrInput from "@/components/CrInput";
import CrTextarea from "@/components/CrTextarea";
import CrSelect from "@/components/CrSelect";
import CrLabel from "@/components/CrLabel";
import CrInfo from "@/components/CrInfo";

export default {
  components: {
    CrHighlightedTitle,
    CrInput,
    CrTextarea,
    CrSelect,
    CrLabel,
    CrInfo,
  },
  props: {
    form: {
      type: Object,
      required: false,
      default: null,
    },
    config: {
      type: Object,
      required: false,
      default: null,
    },
    user_data: {
      type: Object,
      required: false,
      default: null,
    },
    formComputed: {},
  },

  emits: ['showRestrictions', 'validateInput'],

  data() {
    return {}
  },

  computed: {
    createdByOptions() {
      const createdByJson = this.user_data.created_by;
      let result = {};

      Object.keys(createdByJson).map((key) => result[key] = createdByJson[key]['name'])

      return result;
    }
  },

  methods: {},
}
</script>