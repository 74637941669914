<template>
  <div class="navigation-bottom">
    <div class="previous-step"
         v-if="+currentStep > 1"
         @click="$emit('goToStep', +this.currentStep - 1)">
      <img :src="require('../assets/images/icons/arrow_left.svg')">
    </div>

    <div class="next-step" @click="$emit('goToStep', +this.currentStep + 1)">
      <div class="text">{{ nextStepName }}</div>
      <img :src="require('../assets/images/icons/arrow_right_white.svg')">
    </div>
  </div>
</template>

<style scoped lang="scss">
.navigation-bottom {
  padding: 20px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 800px;
  transition: all 0.2s;

  .previous-step {
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
    margin-right: 15px;
    background: #F2F2F2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s;

    &:hover,
    &:focus {
      background-color: #fdf0f4;
    }
  }

  .next-step {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;
    height: 48px;
    background: #E31E51;
    border-radius: 8px;
    padding-right: 15px;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s;

    &:hover,
    &:focus {
      background-color: #cb1545;
    }

    .text {
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.55px;
      color: #FFFFFF;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0 10px;
    }

    img {
      position: absolute;
      right: 15px;
    }
  }
}

#app {
  .inner {
    &.mobile {
      .navigation-bottom {
        max-width: 390px;
      }
    }
  }
}
</style>

<script>
export default {
  components: {},
  props: {
    currentStep: {
      type: [String, Number],
      required: true,
      default: '',
    },

    selectedStrength: {
      type: String,
      required: true,
      default: '',
    },
  },

  emits: ['goToStep'],

  data() {
    return {}
  },

  computed: {
    nextStepName() {
      let name = '';

      if (this.selectedStrength === 'mass') {
        switch (+this.currentStep) {
          case 1:
            name = 'Process Data';
            break;
          case 2:
            name = 'Report Basic Data';
            break;
          case 3:
            name = 'Potential Saving';
            break;
          case 4:
            name = 'Create Report';
            break;
        }
      }

      if (this.selectedStrength === 'surface') {
        switch (+this.currentStep) {
          case 1:
            name = 'Paper Line + App. Data';
            break;
          case 2:
            name = 'Process Data';
            break;
          case 3:
            name = 'Report Basic Data';
            break;
          case 4:
            name = 'Potential Saving';
            break;
          case 5:
            name = 'Create Report';
            break;
        }
      }

      return name;
    }
  },

  methods: {},
}
</script>