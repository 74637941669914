<template>
  <div class="step-content">
    <div class="section">
      <cr-highlighted-title :title="'PM Data'"/>

      <cr-label :title="'Line Speed'"/>
      <cr-input :currency="units.s_step_2.s_jc_sc_50"
                :id="'input.s_step_2.s_jc_sc_50'"
                @focus="this.$emit('showRestrictions', 's_step_2.s_jc_sc_50')"
                @blur="this.$emit('validateInput', 's_step_2.s_jc_sc_50')"
                v-model="form.s_step_2.s_jc_sc_50"/>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_2.s_jc_sc_50'"
               :text="getRestrictionText(restrictions.s_step_2.s_jc_sc_50)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_2.s_jc_sc_50'"
               :text="'Check input value'"/>

      <cr-label :title="'Paper With'"/>
      <cr-input :currency="units.s_step_2.s_jc_sc_51"
                :id="'input.s_step_2.s_jc_sc_51'"
                @focus="this.$emit('showRestrictions', 's_step_2.s_jc_sc_51')"
                @blur="this.$emit('validateInput', 's_step_2.s_jc_sc_51')"
                v-model="form.s_step_2.s_jc_sc_51"/>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_2.s_jc_sc_51'"
               :text="getRestrictionText(restrictions.s_step_2.s_jc_sc_51)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_2.s_jc_sc_51'"
               :text="'Check input value'"/>
    </div>

    <div class="section">
      <cr-highlighted-title :title="'Application Data'"/>

      <cr-label :title="'Application Unit'"/>
      <cr-select :options="applicationUnitOptions"
                 v-model="form.s_step_2.s_jc_sc_52"/>

      <cr-label :title="'Application Rate TOP Side'"/>
      <cr-input :currency="units.s_step_2.s_jc_sc_53"
                :id="'input.s_step_2.s_jc_sc_53'"
                @focus="this.$emit('showRestrictions', 's_step_2.s_jc_sc_53')"
                @blur="this.$emit('validateInput', 's_step_2.s_jc_sc_53')"
                v-model="form.s_step_2.s_jc_sc_53"/>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_2.s_jc_sc_53'"
               :text="getRestrictionText(restrictions.s_step_2.s_jc_sc_53)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_2.s_jc_sc_53'"
               :text="'Check input value'"/>

      <cr-label :title="'Application Rate BOTTOM Side'"/>
      <cr-input :currency="units.s_step_2.s_jc_sc_54"
                :id="'input.s_step_2.s_jc_sc_54'"
                @focus="this.$emit('showRestrictions', 's_step_2.s_jc_sc_54')"
                @blur="this.$emit('validateInput', 's_step_2.s_jc_sc_54')"
                v-model="form.s_step_2.s_jc_sc_54"/>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_2.s_jc_sc_54'"
               :text="getRestrictionText(restrictions.s_step_2.s_jc_sc_54)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_2.s_jc_sc_54'"
               :text="'Check input value'"/>
    </div>

    <div class="section">
      <cr-highlighted-title :title="'Starch Preparation'"/>

      <cr-label :title="'Potential Saving (Starch Degradation Quality)'"/>
      <cr-select :options="potentialSavingsOptions"
                 v-model="form.s_step_2.s_jc_sc_55"/>

      <cr-label :title="'Rating of existing Starch Preparation Plant'"/>

      <div class="potential-savings">
        <div class="block" v-for="saving in potentialSavings" :key="saving">
          <div class="info">
            <div class="name">{{ saving.name }}</div>
            <div class="value">{{ saving.value }}</div>
            <div class="default" v-if="saving.is_default">(default)</div>
          </div>
          <div class="description">{{ saving.description }}</div>
        </div>
      </div>
    </div>


  </div>
</template>

<style scoped lang="scss">
.step-content {
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  padding: 20px 20px 100px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.04);

  .section {
    position: relative;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .margin-bottom {
      margin-bottom: 45px;
    }

    .colored-logo {
      position: absolute;
      top: 0;
      right: 0;
      width: 35%;

      &.colored-logo-1 {
        right: 40%;
      }
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        flex: 1;
      }

      .input-wrapper,
      .select-wrapper {
        width: 35%;

        &.first {
          margin-right: 5%;
        }
      }
    }

    .potential-savings {
      padding: 10px 15px;
      font-size: 14px;
      background: rgba(207, 216, 220, 0.05);
      border: 1px solid #CFD8DC;
      border-radius: 4px;

      .block {
        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .info {
          display: flex;
          margin-bottom: 5px;

          .name {
            font-weight: 700;
          }

          .value, .default {
            color: #C62828;
            margin-left: 8px;
          }

          .default {
            flex: 1;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>

<script>
import CrHighlightedTitle from "@/components/CrHighlightedTitle";
import CrInput from "@/components/CrInput";
import CrLabel from "@/components/CrLabel";
import CrSelect from "@/components/CrSelect";
import CrInfo from "@/components/CrInfo";
import getRestrictionText from "@/utils/getRestrictionText";

export default {
  components: {
    CrHighlightedTitle,
    CrInput,
    CrLabel,
    CrSelect,
    CrInfo,
  },
  props: {
    form: {
      type: Object,
      required: false,
      default: null,
    },
    config: {
      type: Object,
      required: false,
      default: null,
    },
    user_data: {
      type: Object,
      required: false,
      default: null,
    },
    formComputed: {},
  },

  emits: ['showRestrictions', 'validateInput'],

  data() {
    return {
      applicationUnitOptions: this.user_data.application_unit,
      potentialSavings: this.user_data.potential_savings,
    }
  },

  inject: ['units', 'restrictions'],

  setup(){
    return { getRestrictionText }
  },

  computed: {
    potentialSavingsOptions() {
      let obj = {};

      for (const [key, value] of Object.entries(this.potentialSavings)) {
        obj[key] = value['name'] + ' (' + value['value'] + ')';
      }

      return obj;
    }
  },

  methods: {},
}
</script>