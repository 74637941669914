<template>
  <div class="page"
       v-if="isOpen" >
    <div class="page-top" >
      <div class="reset-form" @click="close">
        <img :src="require('../assets/images/icons/close.svg')">
      </div>

      <div class="right-part">
        <div class="title">
          <slot name="title"></slot>
        </div>
      </div>
    </div>
    <div class="page-content">
      <slot name="content"></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: "CrPage",
  data() {
    return {
      /**
       * Open-close flag
       */
      isOpen: false,
    };
  },
  methods: {
    /**
     * Open page
     */
    open() {
      this.isOpen = true;
      window.scrollTo(0, 0);
    },

    /**
     * Close page
     */
    close() {
      this.isOpen = false;
    }
  }
}
</script>

<style scoped lang="scss">

.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("~@/assets/images/background.png");
  background-color: #fff;
  background-size: cover;
  transition: all 0.2s;
  z-index: 100;

  .page-top {
    padding: 20px;
    display: flex;
    align-items: center;

    .reset-form {
      width: 38px;
      height: 38px;
      margin-right: 20px;
      background: #FFFFFF;
      border: 1px solid #F6F6F6;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      transition: all 0.2s;

      &:hover,
      &:focus {
        background-color: #fdf0f4;
      }

      img {
        width: 26px;
        height: 26px;
      }
    }

    .right-part {
      flex: 1;

      .title {
        margin: 10px 0;
        padding: 0 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.55px;
      }
    }
  }

  .page-content {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    padding: 20px 20px 100px;
    border-radius: 30px 30px 0 0;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.04);
    text-align: left;
    min-height: calc(100% - 89px);
  }
}

</style>
