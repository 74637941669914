<template>
  <div class="inner" :class="{mobile: isMobile}">
    <!--    <div id="clicker" @click="toggleView"></div>-->


    <div class="app-container" v-if="!resultIsReady">

      <div class="step step-0" v-if="currentStep === 0">
        <img :src="require('../assets/images/thing.svg')" class="main-image">

        <div class="top">
          <img :src="require('../assets/images/logo_1.svg')" class="top-logo-1">
          <img :src="require('../assets/images/logo_2.svg')" class="top-logo-2">
        </div>

        <div class="mid">
          <h1>starchPERFORMER Savings Calculator</h1>
          <img :src="require('../assets/images/heading_logos.svg')" class="mid-logos">
        </div>

        <div class="bottom">
          <div class="unit-switcher">
            Units
            <cr-select class="select-home" v-model="selectedUnit"
                       :options="{metric: 'Metric Units', us: 'US Customary Units'}"/>
          </div>
          <div class="button button-1" @click="selectStrength('mass')">
            <img :src="require('../assets/images/strength_mass_logo.svg')">

            <div class="texts">
              <div class="title">Mass - Starch</div>
              <div class="description">(Modified, Cationic Starch)</div>
            </div>
          </div>

          <div class="button button-2" @click="selectStrength('surface')">
            <img :src="require('../assets/images/strength_surface_logo.svg')">

            <div class="texts">
              <div class="title">Surface - Starch</div>
              <div class="description">(Native Starch)</div>
            </div>
          </div>
        </div>

        <div class="helper-links">
          <a id="install_pwa" @click="openPWAModal">Browser-App installieren</a>
          <cr-install-instruction ref="installInstructions"/>
        </div>


        <div class="logout-wrapper">
          <a class="logout-button" @click.prevent="$emit('logout')">Logout</a>
        </div>

        <div class="copyright">© 2022 - All Rights Reserved</div>
      </div>

      <div id="steps_mass" v-if="selectedStrength === 'mass'">
        <div class="step step-1" v-if="currentStep === 1">
          <cr-navigation-top
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @resetForm="resetForm"/>

          <CrStepMass1Content :form="form"
                              :formComputed="formComputed"
                              :user_data="user_data"
                              :config="config"
                              @showRestrictions="showRestrictions"
                              @validateInput="validateInput"/>

          <cr-navigation-bottom
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @goToStep="goToStep"/>
        </div>

        <div class="step step-2" v-if="currentStep === 2">
          <cr-navigation-top
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @resetForm="resetForm"/>

          <CrStepMass2Content :form="form"
                              :formComputed="formComputed"
                              :user_data="user_data"
                              :config="config"
                              @showRestrictions="showRestrictions"
                              @validateInput="validateInput"/>

          <cr-navigation-bottom
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @goToStep="goToStep"/>
        </div>

        <div class="step step-3" v-if="currentStep === 3">
          <cr-navigation-top
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @resetForm="resetForm"/>

          <CrStepMass3Content :form="form"
                              :formComputed="formComputed"
                              :user_data="user_data"
                              :config="config"
                              @showRestrictions="showRestrictions"
                              @validateInput="validateInput"/>

          <cr-navigation-bottom
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @goToStep="goToStep"/>
        </div>

        <div class="step step-4" v-if="currentStep === 4">
          <cr-navigation-top
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @resetForm="resetForm"/>

          <CrStepMass4Content :form="form"
                              :formComputed="formComputed"
                              :user_data="user_data"
                              :config="config"
                              @showRestrictions="showRestrictions"
                              @validateInput="validateInput"/>

          <cr-navigation-bottom
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @goToStep="goToStep"/>
        </div>
      </div>

      <div id="steps_surface" v-if="selectedStrength === 'surface'">
        <div class="step step-1" v-if="currentStep === 1">
          <cr-navigation-top
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @resetForm="resetForm"/>

          <CrStepSurface1Content :form="form"
                                 :formComputed="formComputed"
                                 :user_data="user_data"
                                 :config="config"
                                 @showRestrictions="showRestrictions"
                                 @validateInput="validateInput"/>

          <cr-navigation-bottom
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @goToStep="goToStep"/>
        </div>

        <div class="step step-2" v-if="currentStep === 2">
          <cr-navigation-top
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @resetForm="resetForm"/>

          <CrStepSurface2Content :form="form"
                                 :formComputed="formComputed"
                                 :user_data="user_data"
                                 :config="config"
                                 @showRestrictions="showRestrictions"
                                 @validateInput="validateInput"/>

          <cr-navigation-bottom
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @goToStep="goToStep"/>
        </div>

        <div class="step step-3" v-if="currentStep === 3">
          <cr-navigation-top
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @resetForm="resetForm"/>

          <CrStepSurface3Content :form="form"
                                 :formComputed="formComputed"
                                 :user_data="user_data"
                                 :config="config"
                                 @showRestrictions="showRestrictions"
                                 @validateInput="validateInput"/>

          <cr-navigation-bottom
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @goToStep="goToStep"/>
        </div>

        <div class="step step-4" v-if="currentStep === 4">
          <cr-navigation-top
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @resetForm="resetForm"/>

          <CrStepSurface4Content :form="form"
                                 :formComputed="formComputed"
                                 :user_data="user_data"
                                 :config="config"/>

          <cr-navigation-bottom
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @goToStep="goToStep"/>
        </div>

        <div class="step step-5" v-if="currentStep === 5">
          <cr-navigation-top
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @resetForm="resetForm"/>

          <CrStepSurface5Content :form="form"
                                 :formComputed="formComputed"
                                 :user_data="user_data"
                                 :config="config"/>

          <cr-navigation-bottom
              :selectedStrength="selectedStrength"
              :currentStep="currentStep"
              @goToStep="goToStep"/>
        </div>
      </div>
    </div>

    <CrStepMassResult v-if="resultIsReady && selectedStrength === 'mass' && currentStep === 5"
                      :selectedStrength="selectedStrength"
                      :currentStep="currentStep"
                      :form="form"
                      :formComputed="formComputed"
                      :config="config"
                      :user_data="user_data"
                      @goToStep="goToStep"/>

    <CrStepSurfaceResult v-if="resultIsReady && selectedStrength === 'surface' && currentStep === 6"
                         :selectedStrength="selectedStrength"
                         :currentStep="currentStep"
                         :form="form"
                         :formComputed="formComputed"
                         :config="config"
                         :user_data="user_data"
                         @goToStep="goToStep"/>

    <div class="popup" v-if="showLowHighTemperatureNotice">
      <div class="popup-inner">
        <div class="error-message"
             v-if="+this.s_jc_25 < config.min_store_temp || +this.m_jc_25 < config.min_store_temp || +this.m_sp_25 < config.min_store_temp">
          Temperature is too low! Increase starch concentration or feedwater temperature
        </div>

        <div class="error-message" v-if="+this.s_jc_25 > config.max_store_temp">
          Temperature is too high! Starch above boiling point, evaporation losses at storage tank! Lower starch
          concentration or feedwater temperature
        </div>

        <div class="buttons">
          <div class="button button-1" @click="showLowHighTemperatureNotice = false">Edit values</div>
          <div class="button button-2" @click="goToStep(+this.currentStep + 1, true)">
            Confirm values and go to the next page
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrNavigationTop from "@/components/CrNavigationTop";
import CrNavigationBottom from "@/components/CrNavigationBottom";
import CrStepMass1Content from "@/components/steps/CrStepMass1Content";
import CrStepMass2Content from "@/components/steps/CrStepMass2Content";
import CrStepMass3Content from "@/components/steps/CrStepMass3Content";
import CrStepMass4Content from "@/components/steps/CrStepMass4Content";
import CrStepMassResult from "@/components/steps/CrStepMassResult";
import CrStepSurface1Content from "@/components/steps/CrStepSurface1Content";
import CrStepSurface2Content from "@/components/steps/CrStepSurface2Content";
import CrStepSurface3Content from "@/components/steps/CrStepSurface3Content";
import CrStepSurface4Content from "@/components/steps/CrStepSurface4Content";
import CrStepSurface5Content from "@/components/steps/CrStepSurface5Content";
import CrStepSurfaceResult from "@/components/steps/CrStepSurfaceResult";
import configApp from "@/assets/config.json";
import units from "@/assets/units.json";
import restrictions from "@/assets/restrictions.json";
import user_data from "@/assets/user_data.json";
import CrInstallInstruction from "@/components/CrInstallInstruction";
import CrSelect from "@/components/CrSelect.vue";
import {computed} from "vue";

export default {
  components: {
    CrSelect,
    CrInstallInstruction,
    CrNavigationTop,
    CrNavigationBottom,
    CrStepMass1Content,
    CrStepMass2Content,
    CrStepMass3Content,
    CrStepMass4Content,
    CrStepMassResult,
    CrStepSurface1Content,
    CrStepSurface2Content,
    CrStepSurface3Content,
    CrStepSurface4Content,
    CrStepSurface5Content,
    CrStepSurfaceResult,
  },
  emits: ["logout"],
  data() {
    return {
      isMobile: true,
      deferredPrompt: null,
      selectedUnit: 'metric',

      currentStep: 0,
      selectedStrength: '',
      resultIsReady: false,
      showLowHighTemperatureNotice: false,

      user_data: user_data,

      form: {
        m_step_1: {
          m_jc_type: 'jet_cooker',
          /** starch_crops_type */
          m_jc_1: 'potato',
          /**  starch_cost */
          m_jc_2: null,
          /** starch_crops_type */
          m_sp_1: 'potato',
          /** starch_cost */
          m_sp_2: null,
          /** steam_cost_unit */
          m_jc_sc_99: 'money_per_weight',
          /** starch_consumption */
          m_jc_sc_0: null,
          /** steam_cost */
          m_jc_sc_1: null,
          /** electric_costs */
          m_jc_sc_11: null,
          /** feedwater_cost */
          m_jc_sc_2: null,
          /** production_days_per_year */
          m_jc_sc_4: null,
        },
        m_step_2: {
          /** starch_solid_content_I */
          m_jc_4: null,
          /** dissolving_water_temperature */
          m_jc_5: null,
          /** cooking_temp */
          m_jc_10: null,
          /** cooking_temp */
          m_sp_10: null,
          /** dilution_water_temperature I */
          m_jc_21: null,
          /** 2.5, // starch_solid_content_IV */
          m_jc_24: null,
          /** Dilution_with_Clear-Filtrate-Water_possible */
          m_jc_27: 'no',
          /** 2.5, // starch_solid_content_V */
          m_jc_28: null,

          /** starch_solid_content_I */
          m_sp_4: null,
          /** dissolving_water_temperature */
          m_sp_5: null,
          /** dilution_water_temperature I */
          m_sp_21: null,
          /** starch_solid_content_IV */
          m_sp_24: null,
          /** Dilution_with_Clear-Filtrate-Water_possible */
          m_sp_27: 'yes',
          /** starch_solid_content_V */
          m_sp_28: null,
        },
        m_step_3: {
          /** created_by */
          m_jc_sc_80: null,
          /** 'Company Name', // company_name */
          m_jc_sc_81: null,
          /** 'location', // location */
          m_jc_sc_82: null,
          /** 'created for', // created for */
          m_jc_sc_83: null,
          /** 'email', // email */
          m_jc_sc_84: null,
          /** 'mobil', // mobil */
          m_jc_sc_85: null,
          /** 'plant project title', // plant_project_titel */
          m_jc_sc_86: null,
          /** 'site of installation', // site_of_installation */
          m_jc_sc_87: null,
          /** date */
          date: this.toDateFormat(new Date()),
        },

        s_step_1: {
          s_jc_type: 'jet_cooker',
          /** starch_crops_type */
          s_jc_1: 'potato',
          /** starch_cost */
          s_jc_2: null,
          /** starch_crops_type */
          s_sp_1: 'potato',
          /** starch_cost */
          s_sp_2: null,
          /** starch_consumption */
          s_jc_3: null,
          /** steam_cost_unit */
          s_jc_sc_99: 'money_per_weight',
          /** steam_cost */
          s_jc_sc_1: null,
          /** electric_costs */
          s_jc_sc_11: null,
          /** feedwater_cost */
          s_jc_sc_2: null,
          /** production_days_per_year */
          s_jc_sc_4: null,
        },
        s_step_2: {
          /** line_speed */
          s_jc_sc_50: null,
          /** paper_with */
          s_jc_sc_51: null,
          /** application_unit */
          s_jc_sc_52: 'film_press',
          /** application_rate_top_side */
          s_jc_sc_53: null,
          /** application_rate_bottom_side */
          s_jc_sc_54: null,
          /** potential_saving */
          s_jc_sc_55: 'condition_1',
        },
        s_step_3: {
          /** dissolving_water_temperature */
          s_jc_5: null,
          /** dissolving_water_temperature */
          s_sp_5: null,
          /** cooking_temp */
          s_jc_10: null,
          /** cooking_temp */
          s_sp_10: null,
          /** starch_solid_content_I */
          s_jc_4: null,
          s_sp_4: null,

          /** dilution_water_temperature I */
          s_jc_21: null,
          s_sp_21: null,
          /** starch_solid_content_IV */
          s_jc_24: null,
          s_sp_24: null,
          /** inactivation_temperature */
          s_jc_60: null,
          /** inactivation_temperature */
          s_sp_60: null,

          /**
           * Dilution_with_Clear-Filtrate-Water_possible
           * @DONT MAKE NULL
           * */
          s_jc_27: 'no',

          /**
           * Dilution_with_Clear-Filtrate-Water_possible
           * @DONT MAKE NULL
           * */
          s_sp_27: 'no',
        },
        s_step_4: {
          /** created_by */
          s_jc_sc_80: null,
          /** 'Company Name', // company_name */
          s_jc_sc_81: null,
          /** 'location', // location */
          s_jc_sc_82: null,
          /** 'created for', // created for */
          s_jc_sc_83: null,
          /** 'email', // email */
          s_jc_sc_84: null,
          /** 'mobil', // mobil */
          s_jc_sc_85: null,
          /** 'plant project title', // plant_project_titel */
          s_jc_sc_86: null,
          /** 'site of installation', // site_of_installation */
          s_jc_sc_87: null,
          /** date */
          date: this.toDateFormat(new Date()),
        },

      },
    }
  },
  provide() {
    return {
      units: computed(() => this.units),
      restrictions: computed(() => this.restrictions),
      selectedUnit: computed(() => this.selectedUnit),
      steamSwitcherOptions: computed(() => this.steamSwitcherOptions),
      cookerOptions: computed(() => this.cookerOptions),
    }
  },

  beforeCreate() {
    this.$nextTick(function () {
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        // Update UI notify the user they can install the PWA
        // showInstallPromotion();
      });
    })
  },

  computed: {
    config() {
      const config = configApp[this.selectedUnit];
      return {
        starch_crops_type: config.starch_crops_type,
        min_store_temp: Number(config.min_store_temp),
        max_store_temp: Number(config.max_store_temp),
        m_step_1: {
          /** enthalpie_steam_at_3,5_bar_g */
          m_jc_51: Number(config.m_jc_51),
          /** enthalpie_steam_at_3,5_bar_g */
          m_sp_51: Number(config.m_sp_51),
          /** enthalpie_water_at_99c */
          m_jc_52: Number(config.m_jc_52),
          /** enthalpie_water_at_99c */
          m_sp_52: Number(config.m_sp_52),
        },
        m_step_2: {
          /** slurry_specific_heat_capacity */
          m_jc_14: Number(config.m_jc_14),
          /** storage_temperature_target_value */
          m_jc_15: Number(config.m_jc_15),
          /** water_specific_heat_capacity */
          m_jc_19: Number(config.m_jc_19),
          /**  clear_filtrate_water_temperatur */
          m_jc_31: Number(config.m_jc_31),
          /** slurry_specific_heat_capacity */
          m_sp_14: Number(config.m_sp_14),
          /** storage_temperature_target_value */
          m_sp_15: Number(config.m_sp_15),
          /** water_specific_heat_capacity */
          m_sp_19: Number(config.m_sp_19),
          /** clear_filtrate_water_temperatur */
          m_sp_31: Number(config.m_sp_31),
        },
        s_step_1: {
          /** enthalpie_steam_at_3,5_bar_g */
          s_jc_51: Number(config.s_jc_51),
          /** enthalpie_steam_at_3,5_bar_g */
          s_sp_51: Number(config.s_sp_51),
          /** enthalpie_water_at_99c */
          s_jc_52: Number(config.s_jc_52),
          /** enthalpie_water_at_99c */
          s_sp_52: Number(config.s_sp_52),
        },
        s_step_3: {
          /** slurry_specific_heat_capacity */
          s_jc_14: Number(config.s_jc_14),
          /** storage_temperature_target_value */
          s_jc_15: Number(config.s_jc_15),
          /** water_specific_heat_capacity */
          s_jc_19: Number(config.s_jc_19),
          s_jc_31: Number(config.s_jc_31),
          /** slurry_specific_heat_capacity */
          s_sp_14: Number(config.s_sp_14),
          /** storage_temperature_target_value */
          s_sp_15: Number(config.s_sp_15),
          /** water_specific_heat_capacity */
          s_sp_19: Number(config.s_sp_19),
          s_sp_31: Number(config.s_sp_31),
        }
      }
    },
    units() {
      return units[this.selectedUnit];
    },
    restrictions() {
      const unitsRestrictions = restrictions[this.selectedUnit];
      unitsRestrictions.m_step_2.m_jc_24.to = Math.min(+this.m_jc_18, 8);
      unitsRestrictions.m_step_2.m_sp_24.to = Math.min(+this.m_sp_18, 8);
      unitsRestrictions.s_step_3.s_jc_24.to = Math.min(+this.s_jc_18, 22);
      unitsRestrictions.s_step_3.s_sp_24.to = Math.min(+this.s_sp_18, 22);
      return unitsRestrictions;
    },
    steamSwitcherOptions() {
      return {
        'money_per_weight': units[this.selectedUnit].money_per_weight,
        'money_per_power': units[this.selectedUnit].money_per_power,
      }
    },
    cookerOptions() {
      return {jet_cooker: 'Jet Cooker', converter: 'Converter'}
    },
    /** dissolving_water_mass_flow */
    m_jc_6() {
      return this.format((this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_jc_4 * 100) - this.form.m_step_1.m_jc_sc_0);
    },
    /** dissolving_water_mass_flow */
    m_sp_6() {
      return this.format((this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_sp_4 * 100) - this.form.m_step_1.m_jc_sc_0);
    },
    /** slurry_mass_flow_I */
    m_jc_7() {
      return this.format(this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_jc_4 * 100);
    },
    /** slurry_mass_flow_I */
    m_sp_7() {
      return this.format(this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_sp_4 * 100);
    },
    /** cooking_steam_mass_flow */
    m_jc_11() {
      return this.format(this.m_jc_7 * (this.form.m_step_2.m_jc_10 - this.form.m_step_2.m_jc_5) / 650);
    },
    /** cooking_steam_mass_flow */
    m_sp_11() {
      return this.format(this.m_sp_7 * (this.form.m_step_2.m_sp_10 - this.form.m_step_2.m_sp_5) / 650);
    },
    /** starch_solid_content_II */
    m_jc_12() {
      return this.format(100 * this.form.m_step_1.m_jc_sc_0 / this.m_jc_13, 3);
    },
    /** starch_solid_content_II */
    m_sp_12() {
      return this.format(100 * this.form.m_step_1.m_jc_sc_0 / this.m_sp_13, 3);
    },
    /** cooked_starch_mass_flow_I */
    m_jc_13() {
      return this.format(+this.m_jc_11 + +this.m_jc_7);
    },
    /** cooked_starch_mass_flow_I */
    m_sp_13() {
      return this.format(+this.m_sp_11 + +this.m_sp_7);
    },
    /** dilution_water_mass_flow_I. */
    m_jc_16() {
      return this.format((this.config.m_step_2.m_jc_14 * this.m_jc_13 * (this.form.m_step_2.m_jc_10 - this.config.m_step_2.m_jc_15)) / (this.config.m_step_2.m_jc_19 * (this.config.m_step_2.m_jc_15 - this.form.m_step_2.m_jc_21)));
    },
    /** dilution_water_mass_flow_I.I */
    m_sp_16() {
      return this.format((this.config.m_step_2.m_sp_14 * this.m_sp_13 * (this.form.m_step_2.m_sp_10 - this.config.m_step_2.m_sp_15)) / (this.config.m_step_2.m_sp_19 * (this.config.m_step_2.m_sp_15 - this.form.m_step_2.m_sp_21)));
    },
    /** cooked_starch_mass_flow_II */
    m_jc_17() {
      return this.format(+this.m_jc_13 + +this.m_jc_16);
    },
    /** cooked_starch_mass_flow_II */
    m_sp_17() {
      return this.format(+this.m_sp_13 + +this.m_sp_16);
    },
    /** starch_solid_content_III */
    m_jc_18() {
      return this.format(100 * this.form.m_step_1.m_jc_sc_0 / this.m_jc_17, 3);
    },
    /** starch_solid_content_III */
    m_sp_18() {
      return this.format(100 * this.form.m_step_1.m_jc_sc_0 / this.m_sp_17, 3);
    },
    /** diluted_starch_mass_flow_I */
    m_jc_20() {
      return this.format(this.form.m_step_1.m_jc_sc_0 / this.m_jc_18 * 100);
    },
    /** diluted_starch_mass_flow_I */
    m_sp_20() {
      return this.format(this.form.m_step_1.m_jc_sc_0 / this.m_sp_18 * 100);
    },
    /** dilution_water_mass_flow_I.II */
    m_jc_22() {
      if (+this.m_jc_18 <= +this.form.m_step_2.m_jc_24) {
        return 0;
      } else {
        return this.format((this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_jc_24 * 100) - this.m_jc_17);
      }
    },
    /** dilution_water_mass_flow_I.II */
    m_sp_22() {
      if (+this.m_sp_18 <= +this.form.m_step_2.m_sp_24) {
        return 0;
      } else {
        return this.format((this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_sp_24 * 100) - this.m_sp_17);
      }
    },
    /** dilution_water_mass_flow_I*/
    m_jc_23() {
      return this.format(+this.m_jc_16 + +this.m_jc_22);
    },
    /** dilution_water_mass_flow_I */
    m_sp_23() {
      return this.format(+this.m_sp_16 + +this.m_sp_22);
    },
    /** diluted_starch_temperature_I */
    m_jc_25() {
      return this.format((this.m_jc_20 * this.config.m_step_2.m_jc_15 + this.m_jc_22 * this.form.m_step_2.m_jc_21) / (+this.m_jc_20 + +this.m_jc_22));
    },
    /** diluted_starch_temperature_I */
    m_sp_25() {
      return this.format((this.m_sp_20 * this.config.m_step_2.m_sp_15 + this.m_sp_22 * this.form.m_step_2.m_sp_21) / (+this.m_sp_20 + +this.m_sp_22));
    },
    /** diluted_starch_mass_flow_II */
    m_jc_26() {
      return this.format(+this.m_jc_13 + +this.m_jc_23);
    },
    /** diluted_starch_mass_flow_II */
    m_sp_26() {
      return this.format(+this.m_sp_13 + +this.m_sp_23);
    },
    /** dilution_water_mass_flow_II_feedwater */
    m_jc_29() {
      if (this.form.m_step_2.m_jc_27 === 'no') {
        if (+this.form.m_step_2.m_jc_24 <= +this.form.m_step_2.m_jc_28) {
          return 0;
        } else {
          return this.format((this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_jc_28 * 100) - this.m_jc_26);
        }
      } else {
        return 0;
      }
    },
    /** dilution_water_mass_flow_II_feedwater */
    m_sp_29() {
      if (this.form.m_step_2.m_sp_27 === 'no') {
        if (+this.form.m_step_2.m_sp_24 <= +this.form.m_step_2.m_sp_28) {
          return 0;
        } else {
          return this.format((this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_sp_28 * 100) - this.m_sp_26);
        }
      } else {
        return 0;
      }
    },
    /** dilution_water_mass_flow_II_clear_filtrate_water */
    m_jc_30() {
      if (this.form.m_step_2.m_jc_27 === 'yes') {
        if (+this.form.m_step_2.m_jc_24 <= +this.form.m_step_2.m_jc_28) {
          return 0;
        } else {
          return this.format((this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_jc_28 * 100) - this.m_jc_26);
        }
      } else {
        return 0;
      }
    },
    /** dilution_water_mass_flow_II_clear_filtrate_water */
    m_sp_30() {
      if (this.form.m_step_2.m_sp_27 === 'yes') {
        if (+this.form.m_step_2.m_sp_24 <= +this.form.m_step_2.m_sp_28) {
          return 0;
        } else {
          return this.format((this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_sp_28 * 100) - this.m_sp_26);
        }
      } else {
        return 0;
      }
    },
    /** diluted_starch_temperature */
    m_jc_32() {
      if (this.form.m_step_2.m_jc_27 === 'no') {
        return this.format((this.m_jc_26 * this.config.m_step_2.m_jc_14 * this.m_jc_25 + this.m_jc_29 * this.config.m_step_2.m_jc_19 * this.config.m_step_2.m_jc_31) / (this.m_jc_26 * this.form.m_step_2.m_jc_14 + this.m_jc_29 * this.config.m_step_2.m_jc_19));
      } else {
        return this.format((this.m_jc_26 * this.config.m_step_2.m_jc_14 * this.m_jc_25 + this.m_jc_30 * this.config.m_step_2.m_jc_19 * this.config.m_step_2.m_jc_31) / (this.m_jc_26 * this.form.m_step_2.m_jc_14 + this.m_jc_30 * this.config.m_step_2.m_jc_19));
      }
    },
    /** diluted_starch_temperature */
    m_sp_32() {
      if (this.form.m_step_2.m_sp_27 === 'no') {
        return this.format((this.m_sp_26 * this.config.m_step_2.m_sp_14 * this.m_sp_25 + this.m_sp_29 * this.config.m_step_2.m_sp_19 * this.config.m_step_2.m_sp_31) / (this.m_sp_26 * this.config.m_step_2.m_sp_14 + this.m_sp_29 * this.config.m_step_2.m_sp_19));
      } else {
        return this.format((this.m_sp_26 * this.config.m_step_2.m_sp_14 * this.m_sp_25 + this.m_sp_30 * this.config.m_step_2.m_sp_19 * this.config.m_step_2.m_sp_31) / (this.m_sp_26 * this.config.m_step_2.m_sp_14 + this.m_sp_30 * this.config.m_step_2.m_sp_19));
      }
    },
    /** diluted_starch_mass_flow_III */
    m_jc_33() {
      if (this.form.m_step_2.m_jc_27 === 'no') {
        return this.format(+this.m_jc_13 + +this.m_jc_23 + +this.m_jc_29);
      } else {
        return this.format(+this.m_jc_13 + +this.m_jc_23 + +this.m_jc_30);
      }
    },
    /** diluted_starch_mass_flow_III */
    m_sp_33() {
      if (this.form.m_step_2.m_sp_27 === 'no') {
        return this.format(+this.m_sp_13 + +this.m_sp_23 + +this.m_sp_29);
      } else {
        return this.format(+this.m_sp_13 + +this.m_sp_23 + +this.m_sp_30);
      }
    },
    m_jc_34() {
      return this.format(this.roundLastNDigits(((this.form.m_step_1.m_jc_sc_4 * 24 * this.m_jc_11) / 1000) * this.form.m_step_1.m_jc_sc_1, 3));
    },
    m_sp_34() {
      return this.format(this.roundLastNDigits(((this.form.m_step_1.m_jc_sc_4 * 24 * this.m_sp_11) / 1000) * this.form.m_step_1.m_jc_sc_1, 3));
    },
    m_jc_35() {
      return this.format(this.form.m_step_1.m_jc_sc_4 * 24 * this.m_jc_11 * (+this.config.m_step_1.m_jc_51 - +this.config.m_step_1.m_jc_52) / (1000 * 3600));
    },
    m_sp_35() {
      return this.format(this.form.m_step_1.m_jc_sc_4 * 24 * this.m_sp_11 * (+this.config.m_step_1.m_sp_51 - +this.config.m_step_1.m_sp_52) / (1000 * 3600));
    },
    m_jc_36() {
      return this.format(this.roundLastNDigits(this.m_jc_37 * this.form.m_step_1.m_jc_sc_2, 3));
    },
    m_sp_36() {
      return this.format(this.roundLastNDigits(this.m_sp_37 * this.form.m_step_1.m_jc_sc_2, 3));
    },
    m_jc_37() {
      return this.format(this.roundLastNDigits(((this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_jc_24 * 100) - +this.form.m_step_1.m_jc_sc_0 + +this.m_jc_29) / 1000 * this.form.m_step_1.m_jc_sc_4 * 24, 3));
    },
    m_sp_37() {
      return this.format(this.roundLastNDigits(((this.form.m_step_1.m_jc_sc_0 / this.form.m_step_2.m_sp_24 * 100) - +this.form.m_step_1.m_jc_sc_0 + +this.m_sp_29) / 1000 * this.form.m_step_1.m_jc_sc_4 * 24, 3));
    },
    m_jc_39() {
      return this.format(this.roundLastNDigits(this.m_jc_30 / 1000 * this.form.m_step_1.m_jc_sc_4 * 24, 3));
    },
    m_sp_39() {
      return this.format(this.roundLastNDigits(this.m_sp_30 / 1000 * this.form.m_step_1.m_jc_sc_4 * 24, 3));
    },
    m_jc_40() {
      return this.format(+this.m_jc_37 + +this.m_jc_39);
    },
    m_sp_40() {
      return this.format(+this.m_sp_37 + +this.m_sp_39);
    },
    m_jc_41() {
      return this.format(this.roundLastNDigits(this.m_jc_42 * this.form.m_step_1.m_jc_2, 3));
    },
    m_sp_41() {
      return this.format(this.roundLastNDigits(this.m_sp_42 * this.form.m_step_1.m_sp_2, 3));
    },
    m_jc_42() {
      return this.format(this.form.m_step_1.m_jc_sc_0 / 1000 * this.form.m_step_1.m_jc_sc_4 * 24);
    },
    m_sp_42() {
      return this.format(this.form.m_step_1.m_jc_sc_0 / 1000 * this.form.m_step_1.m_jc_sc_4 * 24);
    },
    m_jc_43() {
      if (this.form.m_step_1.m_jc_sc_99 === 'money_per_weight') {
        return this.format(+this.m_jc_34 + +this.m_jc_36 + +this.m_jc_41);
      } else if (this.form.m_step_1.m_jc_sc_99 === 'money_per_power') {
        return this.format(+this.m_jc_73 + +this.m_jc_36 + +this.m_jc_41);
      }

      return '-';
    },
    m_sp_43() {
      if (this.form.m_step_1.m_jc_sc_99 === 'money_per_weight') {
        return this.format(+this.m_sp_34 + +this.m_sp_36 + +this.m_sp_41 + +this.m_sp_70);
      } else if (this.form.m_step_1.m_jc_sc_99 === 'money_per_power') {
        return this.format(+this.m_sp_73 + +this.m_sp_36 + +this.m_sp_41 + +this.m_sp_70);
      }

      return '-';
    },
    m_jc_70() {
      return '-';
    },
    m_sp_70() {
      return this.format(this.roundLastNDigits(this.m_sp_71 * this.form.m_step_1.m_jc_sc_11, 3));
    },
    m_jc_71() {
      return '-';
    },
    m_sp_71() {
      let coef = 1;

      if (+this.form.m_step_1.m_jc_sc_0 < 250) {
        coef = 10;
      } else if (+this.form.m_step_1.m_jc_sc_0 < 450) {
        coef = 20;
      } else if (+this.form.m_step_1.m_jc_sc_0 < 900) {
        coef = 35;
      } else if (+this.form.m_step_1.m_jc_sc_0 < 1600) {
        coef = 45;
      } else if (+this.form.m_step_1.m_jc_sc_0 < 2400) {
        coef = 70;
      } else if (+this.form.m_step_1.m_jc_sc_0 <= 3000) {
        coef = 90;
      }

      return this.format(this.roundLastNDigits(coef * this.form.m_step_1.m_jc_sc_4 * 24, 3));
    },
    m_jc_72() {
      return this.format(this.form.m_step_1.m_jc_sc_4 * 24 * this.m_jc_11 / 1000);
    },
    m_sp_72() {
      return this.format(this.form.m_step_1.m_jc_sc_4 * 24 * this.m_sp_11 / 1000);
    },
    m_jc_73() {
      return this.format(this.roundLastNDigits(this.m_jc_35 * this.form.m_step_1.m_jc_sc_1, 3));
    },
    m_sp_73() {
      return this.format(this.roundLastNDigits(this.m_sp_35 * this.form.m_step_1.m_jc_sc_1, 3));
    },
    m_total() {
      return this.format(this.m_jc_43 - this.m_sp_43);
    },


    s_sp_3() {
      return this.form.s_step_1.s_jc_3 * ((100 - user_data.potential_savings[this.form.s_step_2.s_jc_sc_55].value_number) / 100);
    },
    /** dissolving_water_mass_flow */
    s_jc_6() {
      return this.format((this.form.s_step_1.s_jc_3 / this.form.s_step_3.s_jc_4 * 100) - this.form.s_step_1.s_jc_3);
    },
    /** dissolving_water_mass_flow */
    s_sp_6() {
      return this.format((this.s_sp_3 / this.form.s_step_3.s_sp_4 * 100) - this.s_sp_3);
    },
    /** slurry_mass_flow_I */
    s_jc_7() {
      return this.format(this.form.s_step_1.s_jc_3 / this.form.s_step_3.s_jc_4 * 100);
    },
    /** slurry_mass_flow_I */
    s_sp_7() {
      return this.format(this.s_sp_3 / this.form.s_step_3.s_sp_4 * 100);
    },
    /** cooking_steas_mass_flow */
    s_jc_11() {
      return this.format(this.s_jc_7 * (this.form.s_step_3.s_jc_10 - this.form.s_step_3.s_jc_5) / 650);
    },
    /** cooking_steas_mass_flow */
    s_sp_11() {
      return this.format(this.s_sp_7 * (this.form.s_step_3.s_sp_10 - this.form.s_step_3.s_sp_5) / 650);
    },
    /** cooked_starch_mass_flow_I */
    s_jc_12() {
      return this.format(+this.s_jc_11 + +this.s_jc_7);
    },
    /** cooked_starch_mass_flow_I */
    s_sp_12() {
      return this.format(+this.s_sp_11 + +this.s_sp_7);
    },
    /** starch_solid_content_II */
    s_jc_13() {
      return this.format(100 * this.form.s_step_1.s_jc_3 / this.s_jc_12, 1);
    },
    /** starch_solid_content_II */
    s_sp_13() {
      return this.format(100 * this.s_sp_3 / this.s_sp_12, 1);
    },
    /** steam_consumption_inactivation */
    s_jc_61() {
      return this.format(this.s_jc_12 * (this.form.s_step_3.s_jc_60 - this.form.s_step_3.s_jc_10) / 650);
    },
    /** steam_consumption_inactivation */
    s_sp_61() {
      return this.format(this.s_sp_12 * (this.form.s_step_3.s_sp_60 - this.form.s_step_3.s_sp_10) / 650);
    },
    /** after_inactivation_starch_mass_flow_I.I */
    s_jc_62() {
      return this.format(+this.s_jc_61 + +this.s_jc_12);
    },
    /** after_inactivation_starch_mass_flow_I.I */
    s_sp_62() {
      return this.format(+this.s_sp_61 + +this.s_sp_12);
    },
    /**  after_inactivation_starch_solid_content II.I */
    s_jc_63() {
      return this.format(100 * this.form.s_step_1.s_jc_3 / this.s_jc_62, 1);
    },
    /** after_inactivation_starch_solid_content II.I */
    s_sp_63() {
      return this.format(100 * this.s_sp_3 / this.s_sp_62, 1);
    },
    /** dilution_water_mass_flow_I.I */
    s_jc_16() {
      return this.format((this.config.s_step_3.s_jc_14 * this.s_jc_62 * (this.form.s_step_3.s_jc_60 - this.config.s_step_3.s_jc_15)) / (this.config.s_step_3.s_jc_19 * (this.config.s_step_3.s_jc_15 - this.form.s_step_3.s_jc_21)));
    },
    /** dilution_water_mass_flow_I.I */
    s_sp_16() {
      return this.format((this.config.s_step_3.s_sp_14 * this.s_sp_62 * (this.form.s_step_3.s_sp_60 - this.config.s_step_3.s_sp_15)) / (this.config.s_step_3.s_sp_19 * (this.config.s_step_3.s_sp_15 - this.form.s_step_3.s_sp_21)));
    },
    /** cooked_starch_mass_flow_II */
    s_jc_17() {
      return this.format(+this.s_jc_62 + +this.s_jc_16);
    },
    /** cooked_starch_mass_flow_II */
    s_sp_17() {
      return this.format(+this.s_sp_62 + +this.s_sp_16);
    },
    /** starch_solid_content_III */
    s_jc_18() {
      return this.format(100 * this.form.s_step_1.s_jc_3 / this.s_jc_17, 3);
    },
    /** starch_solid_content_III */
    s_sp_18() {
      return this.format(100 * this.s_sp_3 / this.s_sp_17, 3);
    },
    /** diluted_starch_mass_flow_I */
    s_jc_20() {
      return this.format(this.form.s_step_1.s_jc_3 / this.s_jc_18 * 100);
    },
    /** diluted_starch_mass_flow_I */
    s_sp_20() {
      return this.format(this.s_sp_3 / this.s_sp_18 * 100);
    },
    /** dilution_water_mass_flow_I.II */
    s_jc_22() {
      if (+this.s_jc_18 <= +this.form.s_step_3.s_jc_24) {
        return 0;
      } else {
        return this.format((this.form.s_step_1.s_jc_3 / this.form.s_step_3.s_jc_24 * 100) - this.s_jc_17);
      }
    },
    /** dilution_water_mass_flow_I.II */
    s_sp_22() {
      if (+this.s_sp_18 <= +this.form.s_step_3.s_sp_24) {
        return 0;
      } else {
        return this.format((this.s_sp_3 / this.form.s_step_3.s_sp_24 * 100) - this.s_sp_17);
      }
    },
    /** dilution_water_mass_flow_I */
    s_jc_23() {
      return this.format(+this.s_jc_16 + +this.s_jc_22);
    },
    /** dilution_water_mass_flow_I */
    s_sp_23() {
      return this.format(+this.s_sp_16 + +this.s_sp_22);
    },
    /** diluted_starch_temperature_I */
    s_jc_25() {
      return this.format((this.s_jc_20 * this.config.s_step_3.s_jc_15 + this.s_jc_22 * this.form.s_step_3.s_jc_21) / (+this.s_jc_20 + +this.s_jc_22));
    },
    /** diluted_starch_temperature_I */
    s_sp_25() {
      return this.format((this.s_sp_20 * this.config.s_step_3.s_sp_15 + this.s_sp_22 * this.form.s_step_3.s_sp_21) / (+this.s_sp_20 + +this.s_sp_22));
    },
    s_jc_26() {
      return this.format(+this.s_jc_12 + +this.s_jc_23); //
    },
    s_sp_26() {
      return this.format(+this.s_sp_12 + +this.s_sp_23); //
    },
    s_jc_28() {
      return this.format(this.form.s_step_3.s_jc_24);
    },
    s_sp_28() {
      return this.format(this.form.s_step_3.s_sp_24);
    },
    /** dilution_water_mass_flow_II_feedwater */
    s_jc_29() {
      if (this.form.s_step_3.s_jc_27 === 'no') {
        if (+this.form.s_step_3.s_jc_24 <= +this.s_jc_28) {
          return 0;
        } else {
          return this.format((this.form.s_step_1.s_jc_3 / this.s_jc_28 * 100) - this.s_jc_26);
        }
      } else {
        return 0;
      }
    },
    /** dilution_water_mass_flow_II_feedwater */
    s_sp_29() {
      if (this.form.s_step_3.s_sp_27 === 'no') {
        if (+this.form.s_step_3.s_sp_24 <= +this.s_sp_28) {
          return 0;
        } else {
          return this.format((this.form.s_step_1.s_sp_3 / this.s_sp_28 * 100) - this.s_sp_26);
        }
      } else {
        return 0;
      }
    },
    /** dilution_water_mass_flow_II_clear_filtrate_water */
    s_jc_30() {
      if (this.form.s_step_3.s_jc_27 === 'yes') {
        if (+this.form.s_step_3.s_jc_24 <= +this.s_jc_28) {
          return 0;
        } else {
          return this.format((this.form.s_step_1.s_jc_3 / this.s_jc_28 * 100) - this.s_jc_26);
        }
      } else {
        return 0;
      }
    },
    /** dilution_water_mass_flow_II_clear_filtrate_water */
    s_sp_30() {
      if (this.form.s_step_3.s_sp_27 === 'yes') {
        if (+this.form.s_step_3.s_sp_24 <= +this.s_sp_28) {
          return 0;
        } else {
          return this.format((this.form.s_step_1.s_sp_3 / this.s_sp_28 * 100) - this.s_sp_26);
        }
      } else {
        return 0;
      }
    },
    /** diluted_starch_temperature */
    s_jc_32() {
      if (this.form.s_step_3.s_jc_27 === 'no') {
        return this.format((this.s_jc_26 * this.config.s_step_3.s_jc_14 * this.s_jc_25 + this.s_jc_29 * this.config.s_step_3.s_jc_19 * this.config.s_step_3.s_jc_31) / (this.s_jc_26 * this.config.s_step_3.s_jc_14 + this.s_jc_29 * this.config.s_step_3.s_jc_19));
      } else {
        return this.format((this.s_jc_26 * this.config.s_step_3.s_jc_14 * this.s_jc_25 + this.s_jc_30 * this.config.s_step_3.s_jc_19 * this.config.s_step_3.s_jc_31) / (this.s_jc_26 * this.config.s_step_3.s_jc_14 + this.s_jc_30 * this.config.s_step_3.s_jc_19));
      }
    },
    /** diluted_starch_temperature */
    s_sp_32() {
      if (this.form.s_step_3.s_sp_27 === 'no') {
        return this.format((this.s_sp_26 * this.config.s_step_3.s_sp_14 * this.s_sp_25 + this.s_sp_29 * this.config.s_step_3.s_sp_19 * this.config.s_step_3.s_sp_31) / (this.s_sp_26 * this.config.s_step_3.s_sp_14 + this.s_sp_29 * this.config.s_step_3.s_sp_19));
      } else {
        return this.format((this.s_sp_26 * this.config.s_step_3.s_sp_14 * this.s_sp_25 + this.s_sp_30 * this.config.s_step_3.s_sp_19 * this.config.s_step_3.s_sp_31) / (this.s_sp_26 * this.config.s_step_3.s_sp_14 + this.s_sp_30 * this.config.s_step_3.s_sp_19));
      }
    },
    /** diluted_starch_mass_flow_III */
    s_jc_33() {
      if (this.form.s_step_3.s_jc_27 === 'no') {
        return this.format(+this.s_jc_12 + +this.s_jc_23 + +this.s_jc_29);
      } else {
        return this.format(+this.s_jc_12 + +this.s_jc_23 + +this.s_jc_30);
      }
    },
    /** diluted_starch_mass_flow_III */
    s_sp_33() {
      if (this.form.s_step_3.s_sp_27 === 'no') {
        return this.format(+this.s_sp_12 + +this.s_sp_23 + +this.s_sp_29);
      } else {
        return this.format(+this.s_sp_12 + +this.s_sp_23 + +this.s_sp_30);
      }
    },

    s_jc_34() {
      return this.format(this.roundLastNDigits(((this.form.s_step_1.s_jc_sc_4 * 24 * (+this.s_jc_11 + +this.s_jc_61)) / 1000) * this.form.s_step_1.s_jc_sc_1, 3));
    },
    s_sp_34() {
      return this.format(this.roundLastNDigits(((this.form.s_step_1.s_jc_sc_4 * 24 * (+this.s_sp_11 + +this.s_sp_61)) / 1000) * this.form.s_step_1.s_jc_sc_1, 3));
    },
    s_jc_35() {
      return this.format((this.form.s_step_1.s_jc_sc_4 * 24 * (+this.s_jc_11 + +this.s_jc_61) * (+this.config.s_step_1.s_jc_51 - +this.config.s_step_1.s_jc_52) / (1000 * 3600)));
    },
    s_sp_35() {
      return this.format((this.form.s_step_1.s_jc_sc_4 * 24 * (+this.s_sp_11 + +this.s_sp_61) * (+this.config.s_step_1.s_sp_51 - +this.config.s_step_1.s_sp_52) / (1000 * 3600)));
    },
    s_jc_36() {
      return this.format(this.roundLastNDigits(this.s_jc_37 * this.form.s_step_1.s_jc_sc_2, 3));
    },
    s_sp_36() {
      return this.format(this.roundLastNDigits(this.s_sp_37 * this.form.s_step_1.s_jc_sc_2, 3));
    },
    s_jc_37() {
      return this.format(this.roundLastNDigits(((this.form.s_step_1.s_jc_3 / this.form.s_step_3.s_jc_24 * 100) - +this.form.s_step_1.s_jc_3 + +this.s_jc_29) / 1000 * this.form.s_step_1.s_jc_sc_4 * 24, 3));
    },
    s_sp_37() {
      return this.format(this.roundLastNDigits(((this.s_sp_3 / this.form.s_step_3.s_sp_24 * 100) - +this.s_sp_3 + +this.s_sp_29) / 1000 * this.form.s_step_1.s_jc_sc_4 * 24, 3));
    },
    s_jc_41() {
      return this.format(this.roundLastNDigits(this.s_jc_42 * this.form.s_step_1.s_jc_2, 3));
    },
    s_sp_41() {
      return this.format(this.roundLastNDigits(this.s_sp_42 * this.form.s_step_1.s_sp_2, 3));
    },
    s_jc_42() {
      return this.format(this.form.s_step_1.s_jc_3 / 1000 * this.form.s_step_1.s_jc_sc_4 * 24);
    },
    s_sp_42() {
      return this.format(this.s_sp_3 / 1000 * this.form.s_step_1.s_jc_sc_4 * 24);
    },
    s_jc_43() {
      if (this.form.s_step_1.s_jc_sc_99 === 'money_per_weight') {
        return this.format(+this.s_jc_34 + +this.s_jc_36 + +this.s_jc_41);
      } else if (this.form.s_step_1.s_jc_sc_99 === 'money_per_power') {
        return this.format(+this.s_jc_73 + +this.s_jc_36 + +this.s_jc_41);
      }

      return '-';
    },
    s_sp_43() {
      if (this.form.s_step_1.s_jc_sc_99 === 'money_per_weight') {
        return this.format(+this.s_sp_34 + +this.s_sp_36 + +this.s_sp_41 + +this.s_sp_70);
      } else if (this.form.s_step_1.s_jc_sc_99 === 'money_per_power') {
        return this.format(+this.s_sp_73 + +this.s_sp_36 + +this.s_sp_41 + +this.s_sp_70);
      }

      return '-';
    },
    s_jc_70() {
      return '-';
    },
    s_sp_70() {
      return this.format(this.roundLastNDigits(this.s_sp_71 * this.form.s_step_1.s_jc_sc_11, 3));
    },
    s_jc_71() {
      return '-';
    },
    s_sp_71() {
      let coef = 1;

      if (+this.form.s_step_1.s_jc_3 < 250) {
        coef = 10;
      } else if (+this.form.s_step_1.s_jc_3 < 450) {
        coef = 20;
      } else if (+this.form.s_step_1.s_jc_3 < 900) {
        coef = 35;
      } else if (+this.form.s_step_1.s_jc_3 < 1600) {
        coef = 45;
      } else if (+this.form.s_step_1.s_jc_3 < 2400) {
        coef = 70;
      } else if (+this.form.s_step_1.s_jc_3 <= 3000) {
        coef = 90;
      }

      return this.format(this.roundLastNDigits(coef * this.form.s_step_1.s_jc_sc_4 * 24, 3));
    },
    s_jc_72() {
      return this.format((this.form.s_step_1.s_jc_sc_4 * 24 * (+this.s_jc_11 + +this.s_jc_61) / 1000));
    },
    s_sp_72() {
      return this.format((this.form.s_step_1.s_jc_sc_4 * 24 * (+this.s_sp_11 + +this.s_sp_61) / 1000));
    },
    s_jc_73() {
      return this.format(this.roundLastNDigits(this.s_jc_35 * this.form.s_step_1.s_jc_sc_1, 3));
    },
    s_sp_73() {
      return this.format(this.roundLastNDigits(this.s_sp_35 * this.form.s_step_1.s_jc_sc_1, 3));
    },

    's_total'() {
      return this.format(this.s_jc_43 - this.s_sp_43);
    },

    formComputed() {
      return {
        'm_jc_6': this.m_jc_6,
        'm_sp_6': this.m_sp_6,
        'm_jc_11': this.m_jc_11,
        'm_sp_11': this.m_sp_11,
        'm_jc_18': this.m_jc_18,
        'm_sp_18': this.m_sp_18,
        'm_jc_23': this.m_jc_23,
        'm_sp_23': this.m_sp_23,
        'm_jc_25': this.m_jc_25,
        'm_sp_25': this.m_sp_25,
        'm_jc_29': this.m_jc_29,
        'm_sp_29': this.m_sp_29,
        'm_jc_30': this.m_jc_30,
        'm_sp_30': this.m_sp_30,
        'm_jc_34': this.m_jc_34,
        'm_sp_34': this.m_sp_34,
        'm_jc_35': this.m_jc_35,
        'm_sp_35': this.m_sp_35,
        'm_jc_36': this.m_jc_36,
        'm_sp_36': this.m_sp_36,
        'm_jc_37': this.m_jc_37,
        'm_sp_37': this.m_sp_37,
        'm_jc_39': this.m_jc_39,
        'm_sp_39': this.m_sp_39,
        'm_jc_40': this.m_jc_40,
        'm_sp_40': this.m_sp_40,
        'm_jc_41': this.m_jc_41,
        'm_sp_41': this.m_sp_41,
        'm_jc_42': this.m_jc_42,
        'm_sp_42': this.m_sp_42,
        'm_jc_43': this.m_jc_43,
        'm_sp_43': this.m_sp_43,
        'm_jc_70': this.m_jc_70,
        'm_sp_70': this.m_sp_70,
        'm_jc_71': this.m_jc_71,
        'm_sp_71': this.m_sp_71,
        'm_jc_72': this.m_jc_72,
        'm_sp_72': this.m_sp_72,
        'm_jc_73': this.m_jc_73,
        'm_sp_73': this.m_sp_73,
        'm_total': this.m_total,

        's_sp_3': this.s_sp_3,
        's_jc_6': this.s_jc_6,
        's_sp_6': this.s_sp_6,
        's_jc_11': this.s_jc_11,
        's_sp_11': this.s_sp_11,
        's_jc_18': this.s_jc_18,
        's_sp_18': this.s_sp_18,
        's_jc_61': this.s_jc_61,
        's_sp_61': this.s_sp_61,
        's_jc_23': this.s_jc_23,
        's_sp_23': this.s_sp_23,
        's_jc_25': this.s_jc_25,
        's_sp_25': this.s_sp_25,
        's_jc_34': this.s_jc_34,
        's_sp_34': this.s_sp_34,
        's_jc_35': this.s_jc_35,
        's_sp_35': this.s_sp_35,
        's_jc_36': this.s_jc_36,
        's_sp_36': this.s_sp_36,
        's_jc_37': this.s_jc_37,
        's_sp_37': this.s_sp_37,
        's_jc_41': this.s_jc_41,
        's_sp_41': this.s_sp_41,
        's_jc_42': this.s_jc_42,
        's_sp_42': this.s_sp_42,
        's_jc_43': this.s_jc_43,
        's_sp_43': this.s_sp_43,
        's_jc_70': this.s_jc_70,
        's_sp_70': this.s_sp_70,
        's_jc_71': this.s_jc_71,
        's_sp_71': this.s_sp_71,
        's_jc_72': this.s_jc_72,
        's_sp_72': this.s_sp_72,
        's_jc_73': this.s_jc_73,
        's_sp_73': this.s_sp_73,
        's_total': this.s_total,
      }
    }
  },

  watch: {
    selectedUnit() {
      console.log('selectedUnit', this.selectedUnit)
    },
  },

  methods: {
    toggleView() {
      this.isMobile = !this.isMobile;
    },

    selectStrength(strength) {
      this.selectedStrength = strength;

      this.currentStep = 1;
      window.scrollTo(0, 0);
    },

    resetForm() {
      this.selectedStrength = null;
      // this.form = {};
      Object.assign(this.$data, this.$options.data.apply(this));
      this.currentStep = 0;
      window.scrollTo(0, 0);
    },

    goToStep(step, passLowHighTemperature) {
      this.showLowHighTemperatureNotice = false;
      this.resultIsReady = this.selectedStrength === 'mass' && step === 5 || this.selectedStrength === 'surface' && step === 6;

      if (this.currentStep < step) {
        //validation

        if (this.validateStep(this.currentStep, this.selectedStrength)) {
          if (this.currentStep === 3 && this.selectedStrength === 'surface') {
            // notification about s_jc_25
            if (!passLowHighTemperature && (+this.s_jc_25 < this.config.min_store_temp || +this.s_jc_25 > this.config.max_store_temp)) {
              this.showLowHighTemperatureNotice = true;
              return false;
            }
          }

          if (this.currentStep === 2 && this.selectedStrength === 'mass') {
            // notification about m_jc_25 || m_sp_25
            if (!passLowHighTemperature && (+this.m_jc_25 < this.config.min_store_temp || +this.m_sp_25 < this.config.min_store_temp)) {
              this.showLowHighTemperatureNotice = true;
              return false;
            }
          }

          this.currentStep = step;
          window.scrollTo(0, 0);
        } else {
          const errorInputs = document.querySelectorAll('.input-wrapper.error, .textarea-wrapper.error, .select-wrapper.error');
          if (errorInputs.length > 0) {
            window.scrollTo({
              top: errorInputs[0].offsetTop,
              behavior: 'smooth'
            });
          } else {
            window.scrollTo(0, 0);
          }
        }
      } else {
        this.currentStep = step;
        window.scrollTo(0, 0);
      }
    },

    format(value, digits = 0) {

      if (!isNaN(value) && isFinite(value)) {
        return Number(value).toFixed(digits);
      } else {
        return '-';
      }
    },

    roundLastNDigits(num, digits) {
      if (!num || !parseInt(num, 10) || !digits || !parseInt(digits, 10)) {
        return 0;
      } else {
        return Math.round(num / Math.pow(10, parseInt(digits, 10))) * Math.pow(10, digits);
      }
    },

    toDateFormat(date) {
      let local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    },

    showRestrictions(id) {
      // console.log('showRestrictions: ', id);
      if (document.getElementById('restriction.' + id) !== null) {
        document.getElementById('restriction.' + id).style.display = "block";
      }
      document.getElementById('error.' + id).style.display = "none";
      document.getElementById('input.' + id).classList.remove("error");
    },

    validateInput(id) {
      for (let element of document.getElementsByClassName("info-message restriction")) {
        element.style.display = "none";
      }

      let passed = true;

      let [step, field] = id.split('.', 2);
      let value = this.form[step][field];

      const fieldsWithRestrictions = [
        'm_step_1.m_jc_2',
        'm_step_1.m_sp_2',
        'm_step_1.m_jc_sc_0',
        'm_step_1.m_jc_sc_1',
        'm_step_1.m_jc_sc_11',
        'm_step_1.m_jc_sc_2',
        'm_step_1.m_jc_sc_4',
        'm_step_2.m_jc_4',
        'm_step_2.m_jc_5',
        'm_step_2.m_sp_4',
        'm_step_2.m_sp_5',
        'm_step_2.m_jc_10',
        'm_step_2.m_sp_10',
        'm_step_2.m_jc_21',
        'm_step_2.m_sp_21',
        'm_step_2.m_jc_24',
        'm_step_2.m_sp_24',
        's_step_1.s_jc_2',
        's_step_1.s_sp_2',
        's_step_1.s_jc_3',
        's_step_1.s_jc_sc_1',
        's_step_1.s_jc_sc_11',
        's_step_1.s_jc_sc_2',
        's_step_1.s_jc_sc_4',
        's_step_2.s_jc_sc_50',
        's_step_2.s_jc_sc_51',
        's_step_2.s_jc_sc_53',
        's_step_2.s_jc_sc_54',
        's_step_3.s_jc_4',
        's_step_3.s_sp_4',
        's_step_3.s_jc_21',
        's_step_3.s_sp_21',
        's_step_3.s_jc_5',
        's_step_3.s_sp_5',
        's_step_3.s_jc_10',
        's_step_3.s_sp_10',
        's_step_3.s_jc_24',
        's_step_3.s_sp_24',
        's_step_3.s_jc_60',
        's_step_3.s_sp_60',
      ]

      if (fieldsWithRestrictions.includes(id)) {
        passed = value >= this.restrictions[step][field].from && value <= this.restrictions[step][field].to
      }

      if (value === null || !value.toString().trim().length) {
        passed = false;
      }

      if (!passed) {
        document.getElementById('error.' + id).style.display = "block";
        if (document.getElementById('input.' + id)) {
          document.getElementById('input.' + id).classList.add("error");
        }
      }

      return passed;
    },

    validateStep(step, strength) {

      let validationPassed = true,
          requiredInputs = [];

      if (strength === 'mass') {
        if (step === 1) {
          requiredInputs = [
            'm_step_1.m_jc_2',
            'm_step_1.m_sp_2',
            'm_step_1.m_jc_sc_0',
            'm_step_1.m_jc_sc_1',
            'm_step_1.m_jc_sc_11',
            'm_step_1.m_jc_sc_2',
            'm_step_1.m_jc_sc_4',
          ];
        } else if (step === 2) {
          requiredInputs = [
            'm_step_2.m_jc_4',
            'm_step_2.m_sp_4',
            'm_step_2.m_jc_5',
            'm_step_2.m_sp_5',
            'm_step_2.m_jc_10',
            'm_step_2.m_sp_10',
            'm_step_2.m_jc_21',
            'm_step_2.m_sp_21',
            'm_step_2.m_jc_24',
            'm_step_2.m_sp_24',
            'm_step_2.m_jc_28',
            'm_step_2.m_sp_28',
          ];
        } else if (step === 3) {
          requiredInputs = [
            'm_step_3.m_jc_sc_80',
            'm_step_3.m_jc_sc_81',
          ];
        }
      } else if (strength === 'surface') {
        if (step === 1) {
          requiredInputs = [
            's_step_1.s_jc_2',
            's_step_1.s_sp_2',
            's_step_1.s_jc_3',
            's_step_1.s_jc_sc_1',
            's_step_1.s_jc_sc_11',
            's_step_1.s_jc_sc_2',
            's_step_1.s_jc_sc_4',
          ];
        } else if (step === 2) {
          requiredInputs = [
            's_step_2.s_jc_sc_50',
            's_step_2.s_jc_sc_51',
            's_step_2.s_jc_sc_53',
            's_step_2.s_jc_sc_54',
          ];
        } else if (step === 3) {
          requiredInputs = [
            's_step_3.s_jc_4',
            's_step_3.s_sp_4',
            's_step_3.s_jc_5',
            's_step_3.s_sp_5',
            's_step_3.s_jc_10',
            's_step_3.s_sp_10',
            's_step_3.s_jc_21',
            's_step_3.s_sp_21',
            's_step_3.s_jc_24',
            's_step_3.s_sp_24',
            's_step_3.s_jc_60',
            's_step_3.s_sp_60',
          ];
        } else if (step === 4) {
          requiredInputs = [
            's_step_4.s_jc_sc_80',
            's_step_4.s_jc_sc_81',
          ];
        }
      }

      requiredInputs.forEach((input) => {
        if (!this.validateInput(input)) {
          validationPassed = false;
        }
      })

      return validationPassed;
    },

    async openPWAModal() {
      // Show the prompt
      if (this.deferredPrompt !== null) {
        this.deferredPrompt.prompt();
        const {outcome} = await this.deferredPrompt.userChoice;
        if (outcome === 'accepted') {
          this.deferredPrompt = null;
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User accepted the A2HS prompt');
        }
      } else {
        // Open instructions page
        this.$refs.installInstructions.open()
      }
    }
  },
}
</script>