<template>
  <div class="textarea-wrapper">
    <textarea name="" id="" cols="30" rows="4"
              v-model="selected"
              @focus="focus"
              @blur="blur">></textarea>
  </div>
</template>

<style scoped lang="scss">
.textarea-wrapper {
  position: relative;
  margin-bottom: 10px;

  &.error {
    textarea {
      border: 1px solid red;
      color: red;
      background-color: #ffe2e8;
    }
  }

  textarea {
    position: relative;
    background: rgb(149 161 167 / 5%);
    border: 1px solid #CFD8DC;
    border-radius: 6px;
    width: 100%;
    padding: 12px;
    font-size: 15px;
    font-family: 'Inter', sans-serif;
  }
}
</style>

<script>
export default {
  components: {},
  props: {
    modelValue: {
      default: null,
    },
  },

  emits: ['update:modelValue', 'focus', 'blur'],

  data() {
    return {
      selected: this.modelValue ? this.modelValue : null
    }
  },

  watch: {
    selected(value) {
      this.$emit('update:modelValue', value);
    },
  },

  computed: {},

  methods: {
    focus() {
      this.$emit('focus');
    },

    blur() {
      this.$emit('blur');
    }
  },
}
</script>