<template>
  <AppSteps v-if="isAuth" @logout="logout"/>
  <cr-auth v-else @auth="isAuth = true"/>
</template>

<script>
import './assets/styles/main.scss';
import AppSteps from './components/AppSteps.vue';
import CrAuth from "@/components/CrAuth";
import { userLogin, userLogout } from "@/utils/userAuthentication";

export default {
  name: 'App',
  components: {
    CrAuth,
    AppSteps
  },
  data(){
    return {
      // TODO: False!!!!!!!!!
      isAuth: true,
      authWrong: false
    }
  },
  mounted() {
    const login = localStorage.getItem("login");
    const password = localStorage.getItem("password");
    if (!this.isAuth && login && password){
      this.isAuth = userLogin({login, password});
    }
  },
  methods: {
    /**
     * Logout user
     */
    logout(){
      this.isAuth = false;
      userLogout();
    }
  }
}
</script>