<template>
  <div class="print-page">
    <div class="top-info">
      PGA Anlagenbau Ges.m.b.H. Gewerbeweg 3 A-9241 Wernberg - Member of the IBS-Paper Performance Group
    </div>
    <div class="header">
      Starch Performer - Savings calculation
      <img :src="require(`../../assets/images/strength_${selectedStrength}_logo.svg`)" class="main-image">
      <div class="name">{{ selectedStrengthName }}</div>
    </div>

    <div class="top-part">
      <div class="part left-part">
        <div class="column">
          <div class="text-line flex-column">
            <div class="label w-full">Created for</div>
            <div class="value">
              <div>{{ form.m_step_3.m_jc_sc_81 }}</div>
              <div>{{ form.m_step_3.m_jc_sc_82 }}</div>
            </div>
          </div>
          <div class="text-line flex-column">
            <div class="label w-full">Plant / Project Title</div>
            <pre class="value">{{ form.m_step_3.m_jc_sc_86 }}</pre>
          </div>
        </div>
        <div class="column">
          <div class="text-line">
            <div class="label">Name</div>
            <div class="value">{{ form.m_step_3.m_jc_sc_83 }}</div>
          </div>
          <div class="text-line">
            <div class="label">Mobile</div>
            <div class="value">{{ form.m_step_3.m_jc_sc_85 }}</div>
          </div>
          <div class="text-line">
            <div class="label">Email</div>
            <div class="value">{{ form.m_step_3.m_jc_sc_84 }}</div>
          </div>

          <div class="text-line flex-column">
            <div class="label w-full">Site of Installation</div>
            <pre class="value">{{ form.m_step_3.m_jc_sc_87 }}</pre>
          </div>
        </div>
      </div>
      <div class="part right-part">
        <div class="text-column">
          <div class="text-line full-line">
            <div class="label">Created by</div>
            <pre class="value">{{ user_data.mp5_54 }}</pre>
          </div>
          <div class="text-line full-line">
            <div class="label">Name</div>
            <div class="value">{{ user_data.created_by[form.m_step_3.m_jc_sc_80]["name"] }}</div>
          </div>
          <div class="text-line full-line">
            <div class="label">Mobile</div>
            <div class="value">{{ user_data.created_by[form.m_step_3.m_jc_sc_80]["phone"] }}</div>
          </div>
          <div class="text-line full-line">
            <div class="label">Email</div>
            <div class="value">{{ user_data.created_by[form.m_step_3.m_jc_sc_80]["email"] }}</div>
          </div>
          <div class="text-line full-line">
            <div class="label">Date</div>
            <div class="value">{{ form.m_step_3.date }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-part">
      <div class="left-part">
        <div class="colored-logos">
          <div class="colored-logo colored-logo-1 red">
            <img :src="require('../../assets/images/icons/jet_cooker_logo.svg')">
            <div class="text">{{ cookerOptions[form.m_step_1.m_jc_type] }}</div>
          </div>

          <div class="colored-logo colored-logo-2 green">
            <img :src="require('../../assets/images/icons/starch_performer_logo.svg')">
            <div class="text">starchPERFORMER</div>
          </div>
        </div>

        <div class="step-content">
          <h2>Basic Data</h2>

          <div class="section">
            <cr-highlighted-title :title="'Starch'" class='margin-bottom'/>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Crop Type'"/>
              </div>
              <div class="cell">
                <cr-tag :text="starchCropsTypeOptions[form.m_step_1.m_jc_1]"/>
              </div>
              <div class="cell">
                <cr-tag :text="starchCropsTypeOptions[form.m_step_1.m_sp_1]"/>
              </div>
              <div class="currency"></div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Starch Customer Costs'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_1.m_jc_2"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_1.m_sp_2"/>
              </div>
              <div class="currency">{{ units.m_step_1.m_jc_2 }}</div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Starch Consumption at Paperline'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_1.m_jc_sc_0"/>
              </div>
              <div class="currency currency-long">{{ units.m_step_1.m_jc_sc_0 }}</div>
            </div>
          </div>

          <div class="section">
            <cr-highlighted-title :title="'Energy and Auxiliary Materials'"/>

            <div class="sub-headline">Customer Costs</div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Steam'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_1.m_jc_sc_1"/>
              </div>
<!--              TODO -->
              <div class="currency">{{ steamSwitcherOptions[form.m_step_1.m_jc_sc_99] }}</div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Electricity'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_1.m_jc_sc_11"/>
              </div>
              <div class="currency">{{ units.m_step_1.m_jc_sc_11 }}</div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Feedwater'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_1.m_jc_sc_2"/>
              </div>
              <div class="currency">{{ units.m_step_1.m_jc_sc_2 }}</div>
            </div>
          </div>

          <div class="section">
            <cr-highlighted-title :title="'Production Data'"/>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Days of Production per Year'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_1.m_jc_sc_4"/>
              </div>
              <div class="currency"></div>
            </div>
          </div>
        </div>

        <div class="step-content margin-top">
          <h2>Process Data</h2>

          <div class="section">
            <cr-highlighted-title :title="'Slurry'"/>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Starch Solid Content'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_jc_4"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_sp_4"/>
              </div>
              <div class="currency">{{ units.m_step_2.m_jc_4 }}</div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Dissolving Water Temp.'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_jc_5"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_sp_5"/>
              </div>
              <div class="currency">{{ units.m_step_2.m_jc_5 }}</div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Dissolving Water Amount (Feedwater)'"/>
              </div>
              <div class="cell">
                <cr-tag :type="'red'" :text="numberWithCommas(formComputed.m_jc_6)"/>
              </div>
              <div class="cell">
                <cr-tag :type="'green arrow'" :text="numberWithCommas(formComputed.m_sp_6)"/>
              </div>
              <div class="currency">{{ units.result.m_jc_6 }}</div>
            </div>

            <cr-highlighted-title :title="'Cooking'" class='margin-bottom'/>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Cooking Temp.'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_jc_10"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_sp_10"/>
              </div>
              <div class="currency">{{ units.m_step_2.m_jc_10 }}</div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Steam Consumption (Feedwater)'"/>
              </div>
              <div class="cell">
                <cr-tag :type="'red'" :text="numberWithCommas(formComputed.m_jc_11)"/>
              </div>
              <div class="cell">
                <cr-tag :type="'green arrow'" :text="numberWithCommas(formComputed.m_sp_11)"/>
              </div>
              <div class="currency">{{ units.result.m_jc_11 }}</div>
            </div>

            <cr-highlighted-title :title="'Dilute & Storage'" class='margin-bottom'/>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Dilution Water I Temp.'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_jc_21"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_sp_21"/>
              </div>
              <div class="currency">{{ units.m_step_2.m_jc_21 }}</div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Dilution Water I Amount (Feedwater)'"/>
              </div>
              <div class="cell">
                <cr-tag :type="'red'" :text="numberWithCommas(formComputed.m_jc_23)"/>
              </div>
              <div class="cell">
                <cr-tag :type="'green arrow'" :text="numberWithCommas(formComputed.m_sp_23)"/>
              </div>
              <div class="currency">{{ units.result.m_jc_23 }}</div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Starch Solid Content'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_jc_24"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_sp_24"/>
              </div>
              <div class="currency">{{ units.m_step_2.m_jc_24 }}</div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Storage Temp.'"/>
              </div>
              <div class="cell">
                <cr-tag :text="formComputed.m_jc_25"/>
              </div>
              <div class="cell">
                <cr-tag :text="formComputed.m_sp_25"/>
              </div>
              <div class="currency">{{ units.result.m_jc_25 }}</div>
            </div>

            <cr-highlighted-title :title="'Dilute & Dosing'" class='margin-bottom'/>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Dilution with Clear-Filtrate-Water possible'"/>
              </div>
              <div class="cell">
                <cr-tag :text="dilutionWithClearFiltrateWaterPossibleOptions[form.m_step_2.m_jc_27]"/>
              </div>
              <div class="cell">
                <cr-tag :text="dilutionWithClearFiltrateWaterPossibleOptions[form.m_step_2.m_sp_27]"/>
              </div>
              <div class="currency"></div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Starch Solid Content'"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_jc_28"/>
              </div>
              <div class="cell">
                <cr-tag :text="form.m_step_2.m_sp_28"/>
              </div>
              <div class="currency">{{ units.m_step_2.m_jc_28 }}</div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Dilution Water II Amount (Feedwater)'"/>
              </div>
              <div class="cell">
                <cr-tag :type="'red'" :text="numberWithCommas(formComputed.m_jc_29)"/>
              </div>
              <div class="cell">
                <cr-tag :type="'green'" :text="numberWithCommas(formComputed.m_sp_29)"/>
              </div>
              <div class="currency">{{ units.result.m_jc_29 }}</div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Dilution Water II Amount (Clear-Filtrate-Water)'"/>
              </div>
              <div class="cell">
                <cr-tag :type="'red'" :text="numberWithCommas(formComputed.m_jc_30)"/>
              </div>
              <div class="cell">
                <cr-tag :type="'green'" :text="numberWithCommas(formComputed.m_sp_30)"/>
              </div>
              <div class="currency">{{ units.result.m_jc_30 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-part">
        <div class="colored-logos">
          <div class="colored-logo colored-logo-1 red">
            <img :src="require('../../assets/images/icons/jet_cooker_logo.svg')">
            <div class="text">{{ cookerOptions[form.m_step_1.m_jc_type] }}</div>
          </div>

          <div class="colored-logo colored-logo-2 green">
            <img :src="require('../../assets/images/icons/starch_performer_logo.svg')">
            <div class="text">starchPERFORMER</div>
          </div>
        </div>

        <div class="step-content">
          <h2>Potential Savings</h2>

          <div class="section">
            <div class="line">
              <div class="left-part">
                <img :src="require('../../assets/images/icons/logo_steam.svg')" class="logo">
                <cr-label :title="'Steam'"/>
              </div>
              <div class="cell">
                <cr-tag v-if="form.m_step_1.m_jc_sc_99 === 'money_per_weight'" :type="'red'" :text="numberWithCommas(formComputed.m_jc_34) + '  ' + units.result.m_jc_34"/>
                <cr-tag v-else :type="'red'" :text="numberWithCommas(formComputed.m_jc_73) + '  ' + units.result.m_jc_73"/>
              </div>
              <div class="cell">
                <cr-tag v-if="form.m_step_1.m_jc_sc_99 === 'money_per_weight'" :type="'green'" :text="numberWithCommas(formComputed.m_sp_34) + '  ' + units.result.m_sp_34"/>
                <cr-tag v-else :type="'green'" :text="numberWithCommas(formComputed.m_sp_73) + '  ' + units.result.m_sp_73"/>
              </div>
            </div>

            <div class="line small">
              <div class="left-part">
                <cr-label :title="''"/>
              </div>
              <div class="cell">
                <cr-tag v-if="form.m_step_1.m_jc_sc_99 === 'money_per_power'" :text="numberWithCommas(formComputed.m_jc_35) + ' ' + units.result.m_jc_35"/>
                <cr-tag v-else :text="numberWithCommas(formComputed.m_jc_72) + ' ' + units.result.m_jc_72"/>
              </div>
              <div class="cell">
                <cr-tag v-if="form.m_step_1.m_jc_sc_99 === 'money_per_power'" :text="numberWithCommas(formComputed.m_sp_35) + ' ' + units.result.m_sp_35"/>
                <cr-tag v-else :text="numberWithCommas(formComputed.m_sp_72) + ' ' + units.result.m_sp_72"/>
              </div>
            </div>

            <div class="line">
              <div class="left-part">
                <img :src="require('../../assets/images/icons/logo_electricity.svg')" class="logo">
                <cr-label :title="'Electricity'"/>
              </div>
              <div class="cell">
                <cr-tag :text="formComputed.m_jc_70"/>
              </div>
              <div class="cell">
                <cr-tag :type="'green'" :text="numberWithCommas(formComputed.m_sp_70) + ' ' + units.result.m_sp_70"/>
              </div>
            </div>

            <div class="line small">
              <div class="left-part">
                <cr-label :title="''"/>
              </div>
              <div class="cell">
                <cr-tag :text="formComputed.m_jc_71"/>
              </div>
              <div class="cell">
                <cr-tag :type="'green'" :text="numberWithCommas(formComputed.m_sp_71) + ' ' + units.result.m_sp_71"/>
              </div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Water'"/>
              </div>
              <div class="cell">
                <cr-tag :type="'red'" :text="numberWithCommas(formComputed.m_jc_36) + ' ' + units.result.m_jc_36"/>
              </div>
              <div class="cell">
                <cr-tag :type="'green'" :text="numberWithCommas(formComputed.m_sp_36) + ' ' + units.result.m_sp_36"/>
              </div>
            </div>

            <div class="line">
              <div class="left-part">
                <img :src="require('../../assets/images/icons/logo_fresh_water.svg')" class="logo">
                <cr-label :classes="'small'" :title="'Feedwater'"/>
              </div>
              <div class="cell">
                <cr-tag :text="numberWithCommas(formComputed.m_jc_37) + ' ' + units.result.m_jc_37"/>
              </div>
              <div class="cell">
                <cr-tag :text="numberWithCommas(formComputed.m_sp_37)  + ' ' + units.result.m_sp_37"/>
              </div>
            </div>

            <div class="line unique-line">
              <div class="left-part">
                <img :src="require('../../assets/images/icons/logo_clear_filtrate_water.svg')" class="logo">
                <cr-label :classes="'small'" :title="'Clear-Filtrate-Water as Feedwater substituten'"/>
              </div>
              <div class="cell">
                <cr-tag
                    :text="numberWithCommas(formComputed.m_jc_39) + ' ' + units.result.m_jc_39"/>
              </div>
              <div class="cell">
                <cr-tag
                    :text="numberWithCommas(formComputed.m_sp_39) + ' ' + units.result.m_sp_39"/>
              </div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :classes="'small'" :title="'Total'"/>
              </div>
              <div class="cell">
                <cr-tag
                    :text="numberWithCommas(formComputed.m_jc_40) + ' ' + units.result.m_jc_40"/>
              </div>
              <div class="cell">
                <cr-tag
                    :text="numberWithCommas(formComputed.m_sp_40) + ' ' + units.result.m_sp_40"/>
              </div>
            </div>

            <div class="line">
              <div class="left-part">
                <img :src="require('../../assets/images/icons/logo_strength.svg')" class="logo">
                <cr-label :title="'Starch'"/>
              </div>
              <div class="cell">
                <cr-tag :type="'red'" :text="numberWithCommas(formComputed.m_jc_41) + ' ' + units.result.m_jc_41"/>
              </div>
              <div class="cell">
                <cr-tag :type="'green'" :text="numberWithCommas(formComputed.m_sp_41) + ' ' + units.result.m_sp_41"/>
              </div>
            </div>

            <div class="line small">
              <div class="left-part">
              </div>
              <div class="cell">
                <cr-tag :text="numberWithCommas(formComputed.m_jc_42) + ' ' + units.result.m_jc_42"/>
              </div>
              <div class="cell">
                <cr-tag :text="numberWithCommas(formComputed.m_sp_42) + ' ' + units.result.m_sp_42"/>
              </div>
            </div>

            <div class="line">
              <div class="left-part">
                <cr-label :title="'Total Cost'"/>
              </div>
              <div class="cell">
                <cr-tag :type="'red'" :text="numberWithCommas(formComputed.m_jc_43)  + ' ' + units.result.m_jc_43"/>
              </div>
              <div class="cell">
                <cr-tag :type="'green'" :text="numberWithCommas(formComputed.m_sp_43) + ' ' + units.result.m_sp_43"/>
              </div>
            </div>

            <div class="total-savings-line">
              <span>Potential Savings per Year</span>
              <div class="sum">{{ numberWithCommas(formComputed.m_total) }} {{ units.result.m_total}}</div>
            </div>
          </div>
        </div>

        <img :src="require('../../assets/images/result_thing.jpg')" class="main-image">

        <div class="credits">
          <div class="logos-image">
            <img :src="require('../../assets/images/result_logos.jpg')">
          </div>

          <div class="text-column">
            <div class="text-line full-line">
              <div class="label w-full">PGA - IBS Technologist</div>
            </div>
            <div class="text-line full-line">
              <div class="label">Name</div>
              <div class="value">{{ user_data.mp5_59 }}</div>
            </div>
            <div class="text-line full-line">
              <div class="label">Mobile</div>
              <div class="value">{{ user_data.mp5_60 }}</div>
            </div>
            <div class="text-line full-line">
              <div class="label">Email</div>
              <div class="value">{{ user_data.mp5_61 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-line">
      <div class="step yellow"></div>
      <div class="step red"></div>
      <div class="step white"></div>
    </div>
  </div>

  <div class="print-page-buttons print-hide">
    <div class="button" @click="$emit('goToStep', +this.currentStep - 1)">Go Back</div>
    <div class="button print" @click="print">Print</div>
  </div>
</template>

<script>
import CrHighlightedTitle from "@/components/CrHighlightedTitle";
import CrLabel from "@/components/CrLabel";
import CrTag from "@/components/CrTag";
import {numberWithCommas} from '@/helpers';

export default {
  components: {
    CrHighlightedTitle,
    CrLabel,
    CrTag,
  },
  props: {
    form: {
      type: Object,
      required: false,
      default: null,
    },
    config: {
      type: Object,
      required: false,
      default: null,
    },
    user_data: {
      type: Object,
      required: false,
      default: null,
    },
    formComputed: {},
    selectedStrength: {
      type: String,
      required: true,
      default: '',
    },
    currentStep: {
      type: [String, Number],
      required: true,
      default: '',
    },
  },

  inject: ['units', 'steamSwitcherOptions', 'cookerOptions'],

  emits: ['goToStep'],

  data() {
    return {
      dilutionWithClearFiltrateWaterPossibleOptions: {
        "yes": "Yes",
        "no": "No"
      },

      starchCropsTypeOptions: this.config.starch_crops_type,

    }
  },

  computed: {
    selectedStrengthName() {
      if (this.selectedStrength === 'mass') {
        return 'Mass - Starch';
      } else if (this.selectedStrength === 'surface') {
        return 'Surface - Starch';
      } else {
        return '';
      }
    },
  },

  methods: {
    numberWithCommas,

    print() {
      window.print();
    }
  },
}
</script>