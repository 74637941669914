import { sha1 } from "@/utils/sha1";
import users from "@/users.json";

/**
 * User authentication
 * @param login
 * @param password
 */
export function userLogin({login, password}){
    const encryptPassword = sha1(password);
    const user = users.find((user) => user.login === login);
    return !!(user && user.password === encryptPassword);
}

/**
 * User logout
 */
export function  userLogout(){
    localStorage.removeItem("login");
    localStorage.removeItem("password");
}