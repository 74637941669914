<template>
  <div class="switcher-wrapper component-wrapper">
    <div v-for="(value, key) in options"
         :key="key"
         :class="selected === key ? 'selected' : ''"
         class="single-value">
      <input type="radio"
             :id="key"
             :name="name"
             :value="key"
             v-model="selected">
      <label :for="key">{{ value }}</label>
    </div>
  </div>
</template>

<style scoped lang="scss">
.switcher-wrapper {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  background-color: #fffef5;

  .single-value {
    position: relative;
    flex: 1;
    height: 48px;
    text-align: center;
    line-height: 48px;

    &.selected label {
      background: #E31E51;
      color: #FFFFFF;

      &:hover,
      &:focus {
        background-color: #cb1545;
      }
    }

    .text {
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.55px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0 10px;
    }

    input {
      display: none;
    }

    label {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
      cursor: pointer;
      user-select: none;
      transition: all 0.2s;

      &:hover,
      &:focus {
        background-color: #fdf0f4;
      }
    }
  }
}
</style>

<script>
export default {
  components: {},

  props: {
    modelValue: {
      default: null,
    },
    options: {
      type: Object,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true,
    }
  },

  emits: ['update:modelValue', 'focus', 'blur'],

  data() {
    return {
      selected: this.modelValue ? this.modelValue : null
    }
  },

  watch: {
    selected(value) {
      this.$emit('update:modelValue', value);
    },
  },

  computed: {},

  methods: {
    focus() {
      this.$emit('focus');
    },

    blur() {
      this.$emit('blur');
    },
  },
}
</script>