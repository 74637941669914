<template>
  <cr-page ref="page">
    <template #title>
      Imprint
    </template>
    <template #content>
      <div class="size">
        <div>
          <p class="header">
            / Duty of information according to §5 E-Commerce Act, §14 Company Code, §63 Industrial Code and duty of
            disclosure according to §25 Media Act.
          </p>

          <p class="headings">
            PGA Anlagenbau Ges.m.b.H.
          </p>

          <p>Gewerbeweg 3,</p>
          <p>9241 Wernberg,</p>
          <p class="mb-15">Austria</p>

          <div class="mb-15">
            <div class="flex">
              <p class="mr-1 bold">Business subject:</p>
              <p>Paper industry</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">UID number:</p>
              <p>TU48091308</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Company register number:</p>
              <p>FN146455a</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Commercial register court:</p>
              <p>Regional court Klagenfurt</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Company headquarters:</p>
              <p>9241 Wernberg</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Phone:</p>
              <p>+43 (0) 4252 33030</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Fax:</p>
              <p>+43 (0) 4252 33030-720</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">E-Mail:</p>
              <p>office@pga.co.at</p>
            </div>
          </div>

          <div class="mb-15">
            <div class="flex">
              <p class="mr-1 bold">Member of:</p>
              <p>WKO, regional guild, etc.</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Professional law:</p>
              <p>Trade regulations: www.ris.bka.gv.at</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Supervisory authority/trade authority:</p>
              <p>Commercial Court Vienna</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">State of award:</p>
              <p>Austria</p>
            </div>
          </div>

          <p class="bold">Managing Director</p>
          <p class="mb-15">Ing. Klaus Bartelmuss, Dr. Marc Kaddoura, Hubert Gnezda</p>

          <p class="bold">Contact details of the person responsible for data privacy</p>
          <p>If you have any questions regarding data privacy, please find below the contact details of the responsible
            person or office:</p>
          <p>Siegfried Gruber, M.D., CSE (O.P.P.)</p>
          <p class="mb-15">E-mail address: datenschutz@ibs-austria.com</p>

          <p class="bold">EU Dispute Resolution</p>
          <p>In accordance with the Regulation on Online Dispute Resolution in Consumer Matters (ODR Regulation), we
            would
            like to inform you about the online dispute resolution platform (ODR platform).</p>
          <p>Consumers have the possibility to submit complaints to the online dispute resolution platform of the
            European
            Commission at
            <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE</a>
            . You will find the necessary contact details for this above in our imprint.</p>
          <p class="mb-15">However, we would like to point out that we are not willing or obliged to participate in
            dispute resolution proceedings before a consumer arbitration board.</p>

          <p class="bold">Liability for the contents of this website</p>
          <p>We are constantly developing the contents of this website and make every effort to provide correct and
            up-to-date information. Unfortunately, we cannot assume any liability for the correctness of all content on
            this website, especially for that provided by third parties.</p>
          <p class="mb-15">If you notice any problematic or illegal content, please contact us immediately so that we
            can
            remove the illegal content. You will find the contact details in the imprint.</p>

          <p class="bold">Liability for links on this website</p>
          <p>Our website contains links to other websites for whose content we are not responsible. We are not liable
            for
            linked websites, as we were and are not aware of any unlawful activities, have not noticed any such unlawful
            activities and would remove links immediately if we became aware of any unlawful activities.</p>
          <p class="mb-15">If you notice illegal links on our website, please contact us. You will find the contact
            details in the imprint.</p>

          <p class="bold">Copyright notice</p>
          <p>All contents of this website (pictures, photos, texts, videos) are subject to copyright. Please ask us
            before
            you distribute, reproduce or exploit the contents of this website, such as republishing on other websites.
            If
            necessary, we will legally pursue the unauthorized use of parts of the content of our site.</p>
          <p class="mb-15">If you find any content on this site that infringes copyright, please contact us.</p>

          <p class="bold">Picture credits</p>
          <p>The images, photos and graphics on this website are protected by copyright.</p>
          <p>The image rights are held by:</p>
          <p>The images, photos and graphics on this website are copyrighted.</p>
          <p class="mb-15">All texts are protected by copyright.</p>

          <p class="mb-15">Source Imprint: Created with the <a href="https://www.adsimple.at/impressum-generator/">Impressum
            Generator</a> from AdSimple GmbH.</p>
        </div>

        <div>
          <p class="header">
            / Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und
            Offenlegungspflicht laut §25 Mediengesetz.
          </p>

          <p class="headings">
            PGA Anlagenbau Ges.m.b.H.
          </p>

          <p>Gewerbeweg 3,</p>
          <p>9241 Wernberg,</p>
          <p class="mb-15">Österreich</p>

          <div class="mb-15">
            <div class="flex">
              <p class="mr-1 bold">Unternehmensgegenstand:</p>
              <p>Papierindustrie</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">UID-Nummer:</p>
              <p>ATU48091308</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Firmenbuchnummer:</p>
              <p>FN146455a</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Firmenbuchgericht:</p>
              <p>Landesgericht Klagenfurt</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Firmensitz:</p>
              <p>9241 Wernberg</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Phone:</p>
              <p>+43 (0) 4252 33030</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Fax:</p>
              <p>+43 (0) 4252 33030-720</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">E-Mail:</p>
              <p>office@pga.co.at</p>
            </div>
          </div>

          <div class="mb-15">
            <div class="flex">
              <p class="mr-1 bold">Mitglied bei:</p>
              <p>WKO, Landesinnung, etc.</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Berufsrecht:</p>
              <p>Gewerbeordnung: www.ris.bka.gv.at</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Aufsichtsbehörde/Gewerbebehörde:</p>
              <p>Handelsgericht Wien</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Verleihungsstaat:</p>
              <p>Österreich</p>
            </div>
          </div>

          <p class="bold">Geschäftsführer</p>
          <p class="mb-15">Ing. Klaus Bartelmuss, Dr. Marc Kaddoura, Hubert Gnezda</p>

          <p class="bold">Kontaktdaten des Verantwortlichen für Datenschutz</p>
          <p>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen
            Person bzw. Stelle:</p>
          <p>Mag. Jur. Siegfried Gruber, CSE (O.P.P.)</p>
          <p class="mb-15">E-Mail-Adresse: datenschutz@ibs-austria.com</p>

          <p class="bold">EU-Streitschlichtung</p>
          <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie
            über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.</p>
          <p>Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen
            Kommission unter
            <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE </a>
            zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
          <p class="mb-15">Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

          <p class="bold">Haftung für Inhalte dieser Website</p>
          <p>Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen uns korrekte und aktuelle
            Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser
            Website übernehmen, speziell für jene, die seitens Dritter bereitgestellt wurden. </p>
          <p class="mb-15">ISollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns
            umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten
            im Impressum.</p>

          <p class="bold">Haftung für Links auf dieser Website</p>
          <p>Unsere Website enthält Links zu anderen Websites für deren Inhalt wir nicht verantwortlich sind. Haftung
            für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und
            haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen
            würden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
          <p class="mb-15">Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu
            kontaktieren. Sie finden die Kontaktdaten im Impressum.</p>

          <p class="bold">Urheberrechtshinweis</p>
          <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte fragen Sie
            uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf
            anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der
            Inhalte unserer Seite rechtlich verfolgen.</p>
          <p class="mb-15">Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir
            Sie uns zu kontaktieren.</p>

          <p class="bold">Bildernachweis</p>
          <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
          <div class="flex">
            <p class="mr-1">Die Bilderrechte liegen bei: </p>
            <p class="bold">PGA Anlagenbau Ges.m.b.H.</p>
          </div>
          <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
          <p class="mb-15">Alle Texte sind urheberrechtlich geschützt.</p>

          <p class="mb-15">Quelle Impressum: Erstellt mit dem <a href="https://www.adsimple.at/impressum-generator/">Impressum
            Generator</a> von AdSimple GmbH</p>
        </div>
      </div>
    </template>
  </cr-page>
</template>

<script>
import CrPage from "@/components/CrPage";

export default {
  name: "CrImprint",
  components: {CrPage},
  methods: {
    /**
     * Open page
     */
    open() {
      this.$refs.page.open();
    },
  }
}
</script>

<style scoped>
.header {
  font-weight: bold;
  color: #cc00cc;
}

.headings {
  margin-top: 15px;
  font-weight: bold;
}

.mb-15 {
  margin-bottom: 15px;
}

.flex {
  display: flex;
}

.mr-1 {
  margin-right: 4px;
}

.bold {
  font-weight: bold;
}

.size {
  font-size: 12px;
  line-height: 1.5;
}
</style>