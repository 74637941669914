<template>
  <cr-page ref="page">
    <template #title> Install Instructions </template>
    <template #content>
        <p>
          We were unable to install this app automatically on your device.
        </p>
        <p>To install this app, select your device and follow the instructions*:</p>
        <h2 class="device-header" @click="openPoint">
          iOS and iPadOS
        </h2>
        <div class="device-description">
          <p>You can only install this application using Safari:</p>
          <ol>
            <li>Open this site in Safari</li>
            <li>Choose the Share up-arrow at the bottom of the screen</li>
            <li>Select "Add to Home Screen"</li>
          </ol>
        </div>
        <h2 class="device-header" @click="openPoint">macOS</h2>
        <div class="device-description">
          <p>You can only install this application using Chrome or Firefox</p>
          <ol>
            <li>Open this site in Chrome or Firefox</li>
            <li>
              <p>
                Click the Install App button in the address bar
                <br>
                <strong>OR</strong>
                <br>
                Choose Install PGA from the three-dot overflow menu at the top right.</p>
            </li>
          </ol>
        </div>
        <h2 class="device-header" @click="openPoint">
          Android
        </h2>
        <div class="device-description">
          <ol>
            <li>Open this site in Chrome or Firefox</li>
            <li>Open the three-dot overflow menu</li>
            <li>Install app</li>
          </ol>
        </div>
        <h2 class="device-header" @click="openPoint">
          Windows
        </h2>
        <div class="device-description">
          <p>You can use Chrome or Edge.</p>
          <p><strong>Edge: </strong></p>
          <ol>
            <li>Open this site in Edge</li>
            <li>
              <p>
                Click on the icon on the right side of the address bar that looks like three squares and a plus sign (the hover text is “App available. Install PGA)
                <br>
                <strong>OR</strong>
                <br>
                Choose Apps from the three-dot overflow menu and click Install PGA.
              </p>
            </li>
          </ol>
          <p><strong>Chrome: </strong></p>
          <ol>
            <li>Open this site in Chrome</li>
            <li>
              <p>
                Click the Install App button in the address bar
                <br>
                <strong>OR</strong>
                <br>
                Choose Install PGA from the three-dot overflow menu at the top right.
              </p>
            </li>
          </ol>
        </div>

        <h2 class="device-header" @click="openPoint">Linux</h2>
        <div class="device-description">
          <p>You can use Brave, Chrome, or Edge.</p>
          <p>
            Click Install app button(the hover text is “Install PGA") in the toolbar
          </p>
        </div>
        <h2 class="device-header" @click="openPoint">Chrome OS</h2>
        <div class="device-description">
          <p>
            <strong>Chrome</strong>
          </p>
          <p>
            There’s a circled Plus sign button in the address bar of the browser as well as a menu choice to Install PGA. Choosing either results in your being shunted to the Google Play app store, from which you install the app
          </p>
        </div>
        <p><small>* Application installation is not supported on Opera Mini, Internet explorer, KaiOS Browser.  Please use a different browser to install the application</small></p>
    </template>
  </cr-page>
</template>

<style scoped lang="scss">

.device-header {
  cursor: pointer;
  transition: all 0.2s;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;

  &:hover,
  &:focus {
    background-color: #fdf0f4;
  }

  &:before {
    content: "+ ";
  }
}

.device-description {
  padding: 15px 5px 0;
  display: none;
}

.device-header-open:before {
  content: "- ";
}

.device-header-open + .device-description{
  display: block;
}

strong {
  font-weight: bold;
}

p {
  margin-bottom: 15px;
  line-height: 1.2;
}

ol {
  list-style-type: decimal;
  padding-left: 25px;
  margin-bottom: 15px;
}
ol > li{
  margin-bottom: 15px;
}

small {
  font-size: smaller;
}

</style>

<script>
import crPage from "@/components/CrPage";
export default {
  name: "CrInstallInstruction",
  components: { crPage },
  methods: {
    /**
     * Open page
     */
    open() {
      this.$refs.page.open();
    },

    /**
     * Open/close description for device
     * @param e - event
     */
    openPoint(e){
      if(e && e?.target?.classList && e.target.classList.contains('device-header')){
        e.target.classList.toggle('device-header-open')
      }
    }
  }
}
</script>

