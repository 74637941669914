<template>
  <div class="select-wrapper">
    <select name="" id=""
            @focus="focus"
            @blur="blur"
            v-model="selected">
      <option v-for="(value, key) in options"
              :key="key"
              :value="key">
        {{ value }}
      </option>
    </select>
  </div>
</template>

<style scoped lang="scss">
.select-wrapper {
  position: relative;
  margin-bottom: 10px;

  &.error {
    select {
      border: 1px solid red;
      color: red;
      background-color: #ffe2e8;
    }
  }

  select {
    position: relative;
    height: 50px;
    background: rgb(149 161 167 / 5%);
    border: 1px solid #CFD8DC;
    border-radius: 6px;
    width: 100%;
    padding: 5px 20px 5px 12px;
    font-size: 15px;
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../assets/images/icons/select-arrow.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 4px);
    background-position-y: 12px;
  }
}
</style>

<script>
export default {
  components: {},

  props: {
    modelValue: {
      default: null,
    },
    options: {
      type: Object,
      required: false,
      default: null,
    }
  },

  emits: ['update:modelValue', 'focus', 'blur'],

  data() {
    return {
      selected: this.modelValue ? this.modelValue : null
    }
  },

  watch: {
    selected(value) {
      this.$emit('update:modelValue', value);
    },
  },

  methods: {
    focus() {
      this.$emit('focus');
    },

    blur() {
      this.$emit('blur');
    },
  },
}
</script>