<template>
  <div class="tag" :class="type">
    {{ text }}
    <img :src="require('../assets/images/icons/arrow_green_down.svg')" class="arrow">
  </div>
</template>

<style scoped lang="scss">
.tag {
  padding: 3px 3px;
  height: 19px;
  border-radius: 4px;
  display: inline-flex;
  white-space: nowrap;
  position: relative;
  font-size: 14px;

  &.red {
    background: #FFCCBC;
  }

  &.green {
    background: #C8E6C9;
  }

  &.grey {
    color: #828282;
  }

  & > .arrow {
    display: none;
    position: absolute;
    right: -12px;
    bottom: -4px;
  }

  &.arrow > .arrow {
    display: block;
  }
}
</style>

<script>
export default {
  components: {},
  props: {
    text: {
      type: [String, Number],
      required: false,
      default: '',
    },

    type: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>