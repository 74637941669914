<template>
  <div class="navigation-top">
    <div class="reset-form" @click="$emit('resetForm')">
      <img :src="require('../assets/images/icons/close.svg')">
    </div>

    <div class="right-part">
      <div class="name">{{ selectedStrengthName }}</div>

      <div class="progress-steps">
        <div v-for="index in numberOfSteps"
             class="step"
             :key="index"
             :class="stepClasses(index)"/>
      </div>
    </div>
  </div>

  <div class="step-title">{{ currentStepName }}</div>
</template>

<style scoped lang="scss">
.navigation-top {
  padding: 20px;
  display: flex;
  align-items: center;

  .reset-form {
    width: 38px;
    height: 38px;
    margin-right: 20px;
    background: #FFFFFF;
    border: 1px solid #F6F6F6;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s;

    &:hover,
    &:focus {
      background-color: #fdf0f4;
    }

    img {
      width: 26px;
      height: 26px;
    }
  }

  .right-part {
    flex: 1;

    .name {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.55px;
      color: rgba(70, 68, 68, 0.8);
    }

    .progress-steps {
      display: flex;
      margin-top: 10px;

      .step {
        height: 8px;
        width: calc((100% - 24px) / 4);
        margin-right: 8px;
        background: #DBEADB;
        border-radius: 8px;

        &.green {
          background: #66BB6A;
        }
      }
    }
  }
}

.step-title {
  margin: 10px 0;
  padding: 0 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.55px;
}
</style>

<script>
export default {
  components: {},
  props: {
    selectedStrength: {
      type: String,
      required: true,
      default: '',
    },

    currentStep: {
      type: [String, Number],
      required: true,
      default: '',
    },
  },

  emits: ['resetForm'],

  data() {
    return {}
  },

  computed: {
    selectedStrengthName() {
      if (this.selectedStrength === 'mass') {
        return 'Mass - Starch';
      } else if (this.selectedStrength === 'surface') {
        return 'Surface - Starch';
      } else {
        return '';
      }
    },

    numberOfSteps() {
      if (this.selectedStrength === 'mass') {
        return 4;
      } else if (this.selectedStrength === 'surface') {
        return 5;
      } else {
        return 4;
      }
    },

    currentStepName() {
      let name = '';

      if (this.selectedStrength === 'mass') {
        switch (+this.currentStep) {
          case 1:
            name = 'Basic Data';
            break;
          case 2:
            name = 'Process Data';
            break;
          case 3:
            name = 'Report Basic Data';
            break;
          case 4:
            name = 'Potential Saving';
            break;
          case 5:
            name = 'starchPERFORMER - Savings Calculation';
            break;
        }
      }

      if (this.selectedStrength === 'surface') {
        switch (+this.currentStep) {
          case 1:
            name = 'Basic Data';
            break;
          case 2:
            name = 'Paper Line + App. Data';
            break;
          case 3:
            name = 'Process Data';
            break;
          case 4:
            name = 'Report Basic Data';
            break;
          case 5:
            name = 'Potential Saving';
            break;
          case 6:
            name = 'starchPERFORMER - Savings Calculation';
            break;
        }
      }

      return name;
    },
  },

  methods: {
    stepClasses(step) {
      if (+step <= +this.currentStep) {
        return 'green';
      } else {
        return '';
      }
    }
  },
}
</script>