<template>
  <div class="step-content-3">
    <div class="section">
      <div class="colored-logos">
        <div class="colored-logo colored-logo-1 red">
          <img :src="require('../../assets/images/icons/jet_cooker_logo.svg')">
          <div class="text">{{cookerOptions[form.s_step_1.s_jc_type]}}</div>
        </div>

        <div class="colored-logo colored-logo-2 green">
          <img :src="require('../../assets/images/icons/starch_performer_logo.svg')">
          <div class="text">starchPERFORMER</div>
        </div>
      </div>

      <cr-highlighted-title :title="'Slurry'"/>

      <div class="line">
        <cr-label :title="'Starch Solid Content'"/>
        <cr-input :id="'input.s_step_3.s_jc_4'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_jc_4')"
                  @blur="this.$emit('validateInput', 's_step_3.s_jc_4')"
                  v-model="form.s_step_3.s_jc_4"/>
        <cr-input :id="'input.s_step_3.s_sp_4'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_sp_4')"
                  @blur="this.$emit('validateInput', 's_step_3.s_sp_4')"
                  v-model="form.s_step_3.s_sp_4"/>
        <div class="currency">{{ units.s_step_3.s_jc_4 }}</div>
      </div>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_jc_4'"
               :text="getRestrictionText(restrictions.s_step_3.s_jc_4)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_jc_4'"
               :text="'Check input value'"/>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_sp_4'"
               :text="getRestrictionText(restrictions.s_step_3.s_sp_4)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_sp_4'"
               :text="'Check input value'"/>

      <div class="line">
        <cr-label :title="'Dissolving Water Temperature'"/>
        <cr-input :id="'input.s_step_3.s_jc_5'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_jc_5')"
                  @blur="this.$emit('validateInput', 's_step_3.s_jc_5')"
                  v-model="form.s_step_3.s_jc_5"/>
        <cr-input :id="'input.s_step_3.s_sp_5'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_sp_5')"
                  @blur="this.$emit('validateInput', 's_step_3.s_sp_5')"
                  v-model="form.s_step_3.s_sp_5"/>
        <div class="currency">{{ units.s_step_3.s_jc_5 }}</div>
      </div>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_jc_5'"
               :text="getRestrictionText(restrictions.s_step_3.s_jc_5)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_jc_5'"
               :text="'Check input value'"/>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_sp_5'"
               :text="getRestrictionText(restrictions.s_step_3.s_sp_5)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_sp_5'"
               :text="'Check input value'"/>

      <div class="line grey">
        <cr-label :title="'Dissolving Water Amount (Feedwater)'"/>
        <div class="cell">
          <cr-tag :type="'red'"
                  :text="numberWithCommas(formComputed.s_jc_6)"/>
        </div>
        <div class="cell">
          <cr-tag :type="'green arrow'"
                  :text="numberWithCommas(formComputed.s_sp_6)"/>
        </div>
        <div class="currency">{{ units.result.s_jc_6 }}</div>
      </div>

      <cr-highlighted-title :title="'Cooking'" class='margin-bottom'/>

      <div class="line">
        <cr-label :title="'Cooking Temperature'"/>
        <cr-input :id="'input.s_step_3.s_jc_10'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_jc_10')"
                  @blur="this.$emit('validateInput', 's_step_3.s_jc_10')"
                  v-model="form.s_step_3.s_jc_10"/>
        <cr-input :id="'input.s_step_3.s_sp_10'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_sp_10')"
                  @blur="this.$emit('validateInput', 's_step_3.s_sp_10')"
                  v-model="form.s_step_3.s_sp_10"/>
        <div class="currency">{{ units.s_step_3.s_jc_10 }}</div>
      </div>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_jc_10'"
               :text="getRestrictionText(restrictions.s_step_3.s_jc_10)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_jc_10'"
               :text="'Check input value'"/>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_sp_10'"
               :text="getRestrictionText(restrictions.s_step_3.s_sp_10)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_sp_10'"
               :text="'Check input value'"/>

      <div class="line grey">
        <cr-label :title="'Steam Consumption (Feedwater)'"/>
        <div class="cell">
          <cr-tag :type="'red'"
                  :text="numberWithCommas(formComputed.s_jc_11)"/>
        </div>
        <div class="cell">
          <cr-tag :type="'green arrow'"
                  :text="numberWithCommas(formComputed.s_sp_11)"/>
        </div>
        <div class="currency">{{ units.result.s_jc_11 }}</div>
      </div>


      <cr-highlighted-title :title="'Enzyme Inactivation'" class='margin-bottom'/>

      <div class="line">
        <cr-label :title="'Inactivation Temperature'"/>
        <cr-input :id="'input.s_step_3.s_jc_60'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_jc_60')"
                  @blur="this.$emit('validateInput', 's_step_3.s_jc_60')"
                  v-model="form.s_step_3.s_jc_60"/>
        <cr-input :id="'input.s_step_3.s_sp_60'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_sp_60')"
                  @blur="this.$emit('validateInput', 's_step_3.s_sp_60')"
                  v-model="form.s_step_3.s_sp_60"/>
        <div class="currency">{{ units.s_step_3.s_jc_60 }}</div>
      </div>


      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_jc_60'"
               :text="getRestrictionText(restrictions.s_step_3.s_jc_60)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_jc_60'"
               :text="'Check input value'"/>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_sp_60'"
               :text="getRestrictionText(restrictions.s_step_3.s_sp_60)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_sp_60'"
               :text="'Check input value'"/>

      <div class="line grey">
        <cr-label :title="'Steam Consumption (Feedwater)'"/>
        <div class="cell">
          <cr-tag :type="'red'"
                  :text="numberWithCommas(formComputed.s_jc_61)"/>
        </div>
        <div class="cell">
          <cr-tag :type="'green arrow'"
                  :text="numberWithCommas(formComputed.s_sp_61)"/>
        </div>
        <div class="currency">{{ units.result.s_jc_61 }}</div>
      </div>

      <cr-highlighted-title :title="'Dilute & Storage'" class='margin-bottom'/>

      <div class="line">
        <cr-label :title="'Dilution Water Temperature'"/>
        <cr-input :id="'input.s_step_3.s_jc_21'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_jc_21')"
                  @blur="this.$emit('validateInput', 's_step_3.s_jc_21')"
                  v-model="form.s_step_3.s_jc_21"/>
        <cr-input :id="'input.s_step_3.s_sp_21'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_sp_21')"
                  @blur="this.$emit('validateInput', 's_step_3.s_sp_21')"
                  v-model="form.s_step_3.s_sp_21"/>
        <div class="currency">{{ units.s_step_3.s_jc_21 }}</div>
      </div>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_jc_21'"
               :text="getRestrictionText(restrictions.s_step_3.s_jc_21)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_jc_21'"
               :text="'Check input value'"/>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_sp_21'"
               :text="getRestrictionText(restrictions.s_step_3.s_sp_21)"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_sp_21'"
               :text="'Check input value'"/>

      <div class="line grey">
        <cr-label :title="'Dilution Water Amount (Feedwater)'"/>
        <div class="cell">
          <cr-tag :type="'red'"
                  :text="numberWithCommas(formComputed.s_jc_23)"/>
        </div>
        <div class="cell">
          <cr-tag :type="'green arrow'"
                  :text="numberWithCommas(formComputed.s_sp_23)"/>
        </div>
        <div class="currency">{{ units.result.s_jc_23 }}</div>
      </div>

      <div class="line">
        <cr-label :title="'Starch Solid Content'"/>
        <cr-input :id="'input.s_step_3.s_jc_24'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_jc_24')"
                  @blur="this.$emit('validateInput', 's_step_3.s_jc_24')"
                  v-model="form.s_step_3.s_jc_24"/>
        <cr-input :id="'input.s_step_3.s_sp_24'"
                  @focus="this.$emit('showRestrictions', 's_step_3.s_sp_24')"
                  @blur="this.$emit('validateInput', 's_step_3.s_sp_24')"
                  v-model="form.s_step_3.s_sp_24"/>
        <div class="currency">{{ units.s_step_3.s_jc_24 }}</div>
      </div>

      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_jc_24'"
               :text="+formComputed.s_jc_18 ? getRestrictionText(restrictions.s_step_3.s_jc_24) : 'Please, fill the inputs above'"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_jc_24'"
               :text="'Check input value'"/>
      <cr-info :type="'restriction'"
               :id="'restriction.s_step_3.s_sp_24'"
               :text="+formComputed.s_sp_18 ? getRestrictionText(restrictions.s_step_3.s_sp_24) : 'Please, fill the inputs above'"/>
      <cr-info :type="'error'"
               :id="'error.s_step_3.s_sp_24'"
               :text="'Check input value'"/>

      <div class="line">
        <cr-label :title="'Storage Temperature'"/>
        <div class="cell">
          <cr-tag :type="'grey'" :text="formComputed.s_jc_25"/>
        </div>
        <div class="cell">
          <cr-tag :type="'grey'" :text="formComputed.s_sp_25"/>
        </div>
        <div class="currency">{{ units.result.s_jc_25 }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.step-content-3 {
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  padding: 20px 20px 100px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.04);

  .section {
    position: relative;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .label {
      margin: 0;
    }

    .colored-logos {
      display: flex;
      justify-content: end;
      margin-bottom: 15px;

      .colored-logo {
        width: 30%;

        &.colored-logo-2 {
          margin-right: 5%;
        }
      }
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 64px;
      margin: 0 -20px;
      padding: 0 20px;

      &.grey {
        background-color: #FAFAFA;
      }

      .label {
        flex: 1;
      }

      .input-wrapper,
      .select-wrapper,
      .cell {
        width: 20%;
        margin: 0 2.5%;
      }

      .cell {
        text-align: center;
      }

      .currency {
        width: 10%;
      }
    }

    .highlighted-title {
      margin-top: 20px;
    }
  }
}
</style>

<script>
import CrHighlightedTitle from "@/components/CrHighlightedTitle";
import CrInput from "@/components/CrInput";
import CrLabel from "@/components/CrLabel";
import CrTag from "@/components/CrTag";
import {numberWithCommas} from '@/helpers';
import CrInfo from "@/components/CrInfo";
import getRestrictionText from "@/utils/getRestrictionText";

export default {
  components: {
    CrHighlightedTitle,
    CrInput,
    CrLabel,
    CrTag,
    CrInfo,
  },
  props: {
    form: {
      type: Object,
      required: false,
      default: null,
    },
    config: {
      type: Object,
      required: false,
      default: null,
    },
    user_data: {
      type: Object,
      required: false,
      default: null,
    },
    formComputed: {},
  },

  emits: ['showRestrictions', 'validateInput'],

  data() {
    return {
      dilutionWithClearFiltrateWaterPossibleOptions: {
        "yes": "Yes",
        "no": "No"
      }
    }
  },

  inject: ['restrictions', 'units', 'cookerOptions'],

  setup(){
    return { getRestrictionText }
  },

  computed: {},

  methods: {
    numberWithCommas,
  },
}
</script>