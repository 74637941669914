<template>
  <div class="label" :class="classes">{{ title }} <span v-if="required">*</span></div>
</template>

<style scoped lang="scss">
.label {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.24px;
  margin-bottom: 10px;

  &.small {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
  }

  span {
    color: red;
  }
}
</style>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    classes: {
      type: String,
      required: false,
      default: '',
    }
  },
}
</script>