<template>
  <div class="step-content">
    <div class="section">
      <div class="colored-logos">
        <div class="colored-logo colored-logo-1 red">
          <img :src="require('../../assets/images/icons/jet_cooker_logo.svg')">
          <div class="text">{{ cookerOptions[form.m_step_1.m_jc_type] }}</div>
        </div>

        <div class="colored-logo colored-logo-2 green">
          <img :src="require('../../assets/images/icons/starch_performer_logo.svg')">
          <div class="text">starchPERFORMER</div>
        </div>
      </div>

      <div class="line">
        <div class="left-part">
          <img :src="require('../../assets/images/icons/logo_steam.svg')" class="logo">
          <cr-label :title="'Steam'"/>
        </div>
        <div class="cell">
          <cr-tag v-if="form.m_step_1.m_jc_sc_99 === 'money_per_weight'" :type="'red'" :text="numberWithCommas(formComputed.m_jc_34) + '  ' + units.result.m_jc_34"/>
          <cr-tag v-else :type="'red'" :text="numberWithCommas(formComputed.m_jc_73) + '  ' + units.result.m_jc_73"/>
        </div>
        <div class="cell">
          <cr-tag v-if="form.m_step_1.m_jc_sc_99 === 'money_per_weight'" :type="'green'" :text="numberWithCommas(formComputed.m_sp_34) + '  ' + units.result.m_sp_34"/>
          <cr-tag v-else :type="'green'" :text="numberWithCommas(formComputed.m_sp_73) + '  ' + units.result.m_sp_73"/>
        </div>
      </div>

      <div class="line grey">
        <div class="left-part">
          <img :src="require('../../assets/images/icons/logo_electricity.svg')" class="logo">
          <cr-label :title="'Electricity'"/>
        </div>
        <div class="cell">
          <cr-tag :type="'grey'" :text="numberWithCommas(formComputed.m_jc_70)"/>
        </div>
        <div class="cell">
          <cr-tag :type="'green'" :text="numberWithCommas(formComputed.m_sp_70) + '  ' + units.result.m_sp_70"/>
        </div>
      </div>

      <div class="line">
        <div class="left-part">
          <cr-label :title="'Water'"/>
        </div>
        <div class="cell">
          <cr-tag :type="'red'" :text="numberWithCommas(formComputed.m_jc_36) + '  ' + units.result.m_jc_36"/>
        </div>
        <div class="cell">
          <cr-tag :type="'green'" :text="numberWithCommas(formComputed.m_sp_36) + '  ' + units.result.m_sp_36"/>
        </div>
      </div>

      <div class="line small grey">
        <div class="left-part">
          <img :src="require('../../assets/images/icons/logo_fresh_water.svg')" class="logo">
          <cr-label :classes="'small'" :title="'Feedwater'"/>
        </div>
        <div class="cell">
          <cr-tag :text="numberWithCommas(formComputed.m_jc_37) + '  ' +  units.result.m_jc_37"/>
        </div>
        <div class="cell">
          <cr-tag :text="numberWithCommas(formComputed.m_sp_37) + '  ' +  units.result.m_sp_37"/>
        </div>
      </div>

      <div class="line small grey">
        <div class="left-part">
          <img :src="require('../../assets/images/icons/logo_clear_filtrate_water.svg')" class="logo">
          <cr-label :classes="'small'" :title="'Clear-Filtrate-Water as Feedwater substitution'"/>
        </div>
        <div class="cell">
          <cr-tag :text="numberWithCommas(formComputed.m_jc_39) + ' ' + units.result.m_jc_39"/>
        </div>
        <div class="cell">
          <cr-tag :text="numberWithCommas(formComputed.m_sp_39) + ' ' + units.result.m_sp_39"/>
        </div>
      </div>

      <div class="line small grey">
        <div class="left-part">
          <cr-label :classes="'small'" :title="'Total'"/>
        </div>
        <div class="cell">
          <cr-tag :text="numberWithCommas(formComputed.m_jc_40) + ' ' + units.result.m_jc_40"/>
        </div>
        <div class="cell">
          <cr-tag :text="numberWithCommas(formComputed.m_sp_40) + ' ' + units.result.m_sp_40"/>
        </div>
      </div>

      <div class="line">
        <div class="left-part">
          <img :src="require('../../assets/images/icons/logo_strength.svg')" class="logo">
          <cr-label :title="'Starch'"/>
        </div>
        <div class="cell">
          <cr-tag :type="'red'" :text="numberWithCommas(formComputed.m_jc_41) + '  ' + units.result.m_jc_41"/>
        </div>
        <div class="cell">
          <cr-tag :type="'green'" :text="numberWithCommas(formComputed.m_sp_41) + '  ' + units.result.m_sp_41"/>
        </div>
      </div>

      <div class="line grey">
        <div class="left-part">
          <cr-label :title="'Total Cost'"/>
        </div>
        <div class="cell">
          <cr-tag :type="'red'" :text="numberWithCommas(formComputed.m_jc_43) + ' ' + units.result.m_jc_43"/>
        </div>
        <div class="cell">
          <cr-tag :type="'green'" :text="numberWithCommas(formComputed.m_sp_43) + ' ' + units.result.m_sp_43"/>
        </div>
      </div>

      <div class="bottom-line">
        <span>Potential Savings</span>
        <div class="sum">{{ numberWithCommas(formComputed.m_total) }} {{units.result.m_total}}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.step-content {
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  padding: 20px 20px 100px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.04);

  .section {
    position: relative;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .label {
      margin: 0;
      flex: 1;
      font-size: 13px;
    }

    .colored-logos {
      display: flex;
      justify-content: end;
      margin-bottom: 15px;

      .colored-logo {
        width: 30%;
        margin: 0 1%;
        flex: 0 0 30%;
      }
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 64px;
      margin: 0 -20px;
      padding: 0 10px;

      &.grey {
        background-color: #FAFAFA;
      }

      &.small {
        height: 46px;
        padding: 0 6px;
      }

      .left-part {
        flex: 1;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 45px;

        img {
          position: absolute;
          left: 5px;
          width: 30px;
        }
      }

      .input-wrapper,
      .select-wrapper,
      .cell {
        width: 30%;
        margin: 0 1%;
        flex: 0 0 30%;
      }

      .cell {
        text-align: center;
      }

      .currency {
        width: 10%;
      }
    }

    .bottom-line {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0;

      span {
        margin-right: 15px;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.24px;
      }

      .sum {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.24px;
        color: #27AE60;
      }
    }

    .highlighted-title {
      margin-top: 20px;
    }
  }
}
</style>

<script>
import CrLabel from "@/components/CrLabel";
import CrTag from "@/components/CrTag";
import {numberWithCommas} from '@/helpers';

export default {
  components: {
    CrLabel,
    CrTag,
  },
  props: {
    form: {
      type: Object,
      required: false,
      default: null,
    },
    config: {
      type: Object,
      required: false,
      default: null,
    },
    user_data: {
      type: Object,
      required: false,
      default: null,
    },
    formComputed: {},
  },

  emits: [''],

  data() {
    return {
      todoOptions: {
        '1': '1',
        '2': '3',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
      },

      salesPersons: {
        'sales_1': 'Robert',
        'sales_2': 'Robert',
      }
    }
  },

  inject: ['units', 'cookerOptions'],

  computed: {},

  methods: {
    numberWithCommas
  },
}
</script>