<template>
  <div class="dropdown" ref="dropdown">
    <button class="dropbtn" @click="isOpen = !isOpen">
      <slot>
        {{ modelValue }}
      </slot>
    </button>
    <div class="dropdown-content" :class="{active: isOpen}" v-if="Object.keys(options).length">
      <a href="#"
         v-for="(option, value) in options"
         :key="value"
         @click.prevent="selectOption(value)">
        {{ option }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CrDropDown",
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    modelValue: {
      default: null,
    },
    options: {
      type: Object,
      required: false
    }
  },
  mounted() {
    document.body.addEventListener('click', this.closeDropdown)
  },
  unMounted() {
    document.body.removeEventListener('click', this.closeDropdown)
  },
  methods: {
    selectOption(value) {
      this.$emit('update:modelValue', value);
      this.isOpen = false;
    },
    closeDropdown(event) {
      if (this.$refs?.dropdown && !this.$refs.dropdown.contains(event.target))
        this.isOpen = false;
    }
  }
}
</script>

<style scoped lang="scss">
/* Style The Dropdown Button */
.dropbtn {
  padding: 5px;
  font-size: 15px;
  background-color: transparent;
  color: inherit;
  width: 100%;
  border: 1px solid #CFD8DC;
  border-radius: 6px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../assets/images/icons/select-arrow.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 4px);
  background-position-y: 12px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 100%;
  background: #fafafa;
  border: 1px solid #CFD8DC;
  border-radius: 6px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown .dropdown-content.active {
  display: block;
}

</style>