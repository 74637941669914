<template>
  <div class="inner mobile">
    <div class="app-container">
      <div class="login">
        <div class="login-form">
          <div class="top">
            <img :src="require('../assets/images/logo_1.svg')" class="top-logo-1">
            <img :src="require('../assets/images/logo_2.svg')" class="top-logo-2">
          </div>
          <h1 class="login-title">Log in</h1>
          <div class="input-wrapper">
            <input type="email" placeholder="Login" class="form-input" v-model="login" autocomplete="email">
          </div>
          <div class="input-wrapper">
            <input type="password" placeholder="Password" class="form-input" v-model="password" autocomplete="password">
          </div>
          <div class="input-wrapper">
            <button class="form-button" @click="submit">Log In</button>
          </div>
          <div class="form-message">
            {{ message }}
          </div>
        </div>
        <footer>
          <div class="helper-links">
            <div>
              <a @click="openPage('imprint')">Imprint</a>
              <cr-imprint ref="imprint" />
            </div>
            <div>
              <a @click="openPage('data-protection')">Data Protection</a>
              <cr-data-protection ref="data-protection" />
            </div>
          </div>
          <div class="copyright">© 2022 - All Rights Reserved</div>
        </footer>
      </div>

    </div>
  </div>
</template>

<script>

import { userLogin } from "@/utils/userAuthentication";
import CrImprint from "@/components/CrImprint";
import CrDataProtection from "@/components/CrDataProtection";

export default {
  name: "CrAuth",
  components: {CrDataProtection, CrImprint},
  emits: ["auth"],
  data() {
    return {
      login: "",
      password: "",
      message: ""
    }
  },
  watch: {
    login() {
      this.message = "";
    },
    password() {
      this.message = "";
    }
  },
  methods: {
    submit() {
      if (!this.login || !this.password) {
        this.message = "Please fill out all fields";
        return;
      }
      const auth = userLogin({login: this.login, password: this.password});
      if (!auth){
        this.message = "Login or password is incorrect";
      } else {
        this.$emit("auth");
        localStorage.setItem("login", this.login);
        localStorage.setItem("password", this.password);
      }
    },
    openPage(page) {
      this.$refs[page].open();
    }
  }
}
</script>

<style scoped lang="scss">

.login {
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-flow: column wrap;

  .login-form {
    padding: 30px 20px;
    background: url("~@/assets/images/thing.svg") no-repeat 100% 15px;
    min-height: 500px;

    .top {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }

    .top-logo-1 {
      height: 40px;
      margin-right: 20px;
    }

    .top-logo-2 {
      height: 25px;
    }

    .login-title {
      font-weight: 700;
      font-size: 26px;
      line-height: 48px;
      text-align: center;
      margin-bottom: 20px;
    }

    .input-wrapper {
      margin-bottom: 20px;

      .form-input {
        font-family: "Inter", sans-serif;
        position: relative;
        height: 50px;
        background: #fafafb;
        border: 1px solid #CFD8DC;
        border-radius: 6px;
        width: 100%;
        padding: 5px 12px;
        font-size: 15px;
      }

      .form-button {
        padding: 15px;
        box-shadow: 0px -2px 10px rgb(0 0 0 / 10%);
        width: 100%;
        transition: all 0.2s;
        background: #E31E51;
        border: 0;
        border-radius: 8px;
        cursor: pointer;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.55px;
        color: #FFFFFF;

        &:hover,
        &:active,
        &:focus {
          background-color: #cb1545;
        }
      }
    }
    .form-message {
      font-size: 18px;
      color: #cb1545;
      letter-spacing: -0.55px;
    }


  }

  footer {
    margin-top: auto;

    .helper-links {
      padding: 85px 35px 10px;
      text-align: center;

      a {
        display: inline-block;
        margin: 0 12px 15px;
        color: grey;
        cursor: pointer;
        user-select: none;
        transition: 0.1s ease-in-out;

        &:hover,
        &:focus {
          color: #000;
          box-shadow: 0 2px 0 #E31E51;
        }
      }
    }

    .copyright {
      padding: 15px 0;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: -0.24px;
      color: #828282;
    }
  }
}


</style>