<template>
  <div class="step-content">
    <div class="section">
      <cr-highlighted-title :title="'Starch'" class='margin-bottom'/>

      <div class="colored-logo colored-logo-1 red">

        <cr-drop-down
            :options="cookerOptions"
            v-model="form.m_step_1.m_jc_type"
        >
          <img :src="require('../../assets/images/icons/jet_cooker_logo.svg')">
          <div class="text">{{cookerOptions[form.m_step_1.m_jc_type]}}</div>
        </cr-drop-down>
      </div>


      <div class="colored-logo colored-logo-2 green" >
        <img :src="require('../../assets/images/icons/starch_performer_logo.svg')">
        <div class="text">starchPERFORMER</div>
      </div>

      <div class="line">
        <cr-label :title="'Crop Type'"/>

        <cr-select :options="starchCropsTypeOptions"
                   v-model="form.m_step_1.m_jc_1"
                   class="first"/>

        <cr-select :options="starchCropsTypeOptions"
                   v-model="form.m_step_1.m_sp_1"/>
      </div>

      <div class="line">
        <cr-label :title="'Customer Starch Costs'"/>
        <cr-input :currency="units.m_step_1.m_jc_2"
                  class="first"
                  :id="'input.m_step_1.m_jc_2'"
                  @focus="this.$emit('showRestrictions', 'm_step_1.m_jc_2')"
                  @blur="this.$emit('validateInput', 'm_step_1.m_jc_2')"
                  v-model="form.m_step_1.m_jc_2"/>
        <cr-input :currency="units.m_step_1.m_sp_2"
                  :id="'input.m_step_1.m_sp_2'"
                  @focus="this.$emit('showRestrictions', 'm_step_1.m_sp_2')"
                  @blur="this.$emit('validateInput', 'm_step_1.m_sp_2')"
                  v-model="form.m_step_1.m_sp_2"/>
      </div>

      <cr-info :type="'restriction'"
               :id="'restriction.m_step_1.m_jc_2'"
               :text="getRestrictionText(restrictions.m_step_1.m_jc_2)"/>
      <cr-info :type="'error'"
               :id="'error.m_step_1.m_jc_2'"
               :text="'Check input value'"/>

      <cr-info :type="'restriction'"
               :id="'restriction.m_step_1.m_sp_2'"
               :text="getRestrictionText(restrictions.m_step_1.m_sp_2)"/>
      <cr-info :type="'error'"
               :id="'error.m_step_1.m_sp_2'"
               :text="'Check input value'"/>

      <cr-label :title="'Starch Consumption at Paperline'"/>
      <cr-input :currency="units.m_step_1.m_jc_sc_0"
                :id="'input.m_step_1.m_jc_sc_0'"
                @focus="this.$emit('showRestrictions', 'm_step_1.m_jc_sc_0')"
                @blur="this.$emit('validateInput', 'm_step_1.m_jc_sc_0')"
                v-model="form.m_step_1.m_jc_sc_0"/>

      <cr-info :type="'restriction'"
               :id="'restriction.m_step_1.m_jc_sc_0'"
               :text="getRestrictionText(restrictions.m_step_1.m_jc_sc_0)"/>
      <cr-info :type="'error'"
               :id="'error.m_step_1.m_jc_sc_0'"
               :text="'Consumption out of nominal range, consult a product technologist'"/>
    </div>

    <div class="section">
      <cr-highlighted-title :title="'Energy and Auxiliary Materials'"/>

      <cr-label :title="'Customer Steam Costs'"/>
      <cr-switcher v-model="form.m_step_1.m_jc_sc_99"
                   :name="'steam_switcher'"
                   :options="steamSwitcherOptions"/>

      <cr-input :currency="steamSwitcherOptions[form.m_step_1.m_jc_sc_99]"
                :id="'input.m_step_1.m_jc_sc_1'"
                @focus="this.$emit('showRestrictions', 'm_step_1.m_jc_sc_1')"
                @blur="this.$emit('validateInput', 'm_step_1.m_jc_sc_1')"
                v-model="form.m_step_1.m_jc_sc_1"/>

      <cr-info :type="'restriction'"
               :id="'restriction.m_step_1.m_jc_sc_1'"
               :text="getRestrictionText(restrictions.m_step_1.m_jc_sc_1)"/>
      <cr-info :type="'error'"
               :id="'error.m_step_1.m_jc_sc_1'"
               :text="'Check input value'"/>

      <cr-label :title="'Customer Electricity Costs'"/>
      <cr-input :currency="units.m_step_1.m_jc_sc_11"
                :id="'input.m_step_1.m_jc_sc_11'"
                @focus="this.$emit('showRestrictions', 'm_step_1.m_jc_sc_11')"
                @blur="this.$emit('validateInput', 'm_step_1.m_jc_sc_11')"
                v-model="form.m_step_1.m_jc_sc_11"/>

      <cr-info :type="'restriction'"
               :id="'restriction.m_step_1.m_jc_sc_11'"
               :text="getRestrictionText(restrictions.m_step_1.m_jc_sc_11)"/>
      <cr-info :type="'error'"
               :id="'error.m_step_1.m_jc_sc_11'"
               :text="'Check input value'"/>

      <cr-label :title="'Customer Feedwater Costs'"/>
      <cr-input :currency="units.m_step_1.m_jc_sc_2"
                :id="'input.m_step_1.m_jc_sc_2'"
                @focus="this.$emit('showRestrictions', 'm_step_1.m_jc_sc_2')"
                @blur="this.$emit('validateInput', 'm_step_1.m_jc_sc_2')"
                v-model="form.m_step_1.m_jc_sc_2"/>

      <cr-info :type="'restriction'"
               :id="'restriction.m_step_1.m_jc_sc_2'"
               :text="getRestrictionText(restrictions.m_step_1.m_jc_sc_2)"/>
      <cr-info :type="'error'"
               :id="'error.m_step_1.m_jc_sc_2'"
               :text="'Check input value'"/>
    </div>

    <div class="section">
      <cr-highlighted-title :title="'Production Data'"/>

      <cr-label :title="'Days of Production per Year'"/>
      <cr-input :currency="units.m_step_1.m_jc_sc_4"
                :id="'input.m_step_1.m_jc_sc_4'"
                @focus="this.$emit('showRestrictions', 'm_step_1.m_jc_sc_4')"
                @blur="this.$emit('validateInput', 'm_step_1.m_jc_sc_4')"
                v-model="form.m_step_1.m_jc_sc_4"/>

      <cr-info :type="'restriction'"
               :id="'restriction.m_step_1.m_jc_sc_4'"
               :text="getRestrictionText(restrictions.m_step_1.m_jc_sc_4)"/>
      <cr-info :type="'error'"
               :id="'error.m_step_1.m_jc_sc_4'"
               :text="'Check input value'"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.step-content {
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  padding: 20px 20px 100px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.04);

  .section {
    position: relative;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .margin-bottom {
      margin-bottom: 45px;
    }

    .colored-logo {
      position: absolute;
      top: 0;
      right: 0;
      width: 35%;

      &.colored-logo-1 {
        right: 40%;
      }

      &.colored-logo-2 {
        padding: 5px;
      }
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        flex: 1;
      }

      .input-wrapper,
      .select-wrapper {
        width: 35%;

        &.first {
          margin-right: 5%;
        }
      }
    }
  }
}
</style>

<script>
import CrHighlightedTitle from "@/components/CrHighlightedTitle";
import CrInput from "@/components/CrInput";
import CrLabel from "@/components/CrLabel";
import CrSelect from "@/components/CrSelect";
import CrInfo from "@/components/CrInfo";
import CrSwitcher from "@/components/CrSwitcher";
import getRestrictionText from "@/utils/getRestrictionText";
import CrDropDown from "@/components/CrDropDown.vue";

export default {
  components: {
    CrDropDown,
    CrHighlightedTitle,
    CrInput,
    CrLabel,
    CrSelect,
    CrInfo,
    CrSwitcher,
  },
  props: {
    form: {
      type: Object,
      required: false,
      default: null,
    },
    config: {
      type: Object,
      required: false,
      default: null,
    },
    user_data: {
      type: Object,
      required: false,
      default: null,
    },
    formComputed: {},
  },

  inject: ['restrictions', 'units', 'selectedUnit', 'steamSwitcherOptions', 'cookerOptions'],

  emits: ['showRestrictions', 'validateInput'],

  setup() {
    return {getRestrictionText}
  },

  data() {
    return {
      starchCropsTypeOptions: this.config.starch_crops_type,
    }
  },

  computed: {},

  methods: {},
}
</script>
