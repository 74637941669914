<template>
  <cr-page ref="page">
    <template #title>
      Data Protection
    </template>
    <template #content>
      <div class="size">
        <div>
          <p class="header mb-15">/Information according to Art 13 DSGVO</p>
          <p class="mb-15">Name and contact details of the responsible person:</p>

          <p>PGA Anlagenbau Ges.m.b.H.</p>
          <p>Gewerbeweg 3,</p>
          <p>9241 Wernberg,</p>
          <p class="mb-15">Austria</p>

          <div class="mb-15">
            <div class="flex">
              <p class="mr-1 bold">Tel.:</p>
              <p>+43 (0) 4252 33030</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Fax:</p>
              <p>+43 (0) 4252 33030-720</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">E-mail:</p>
              <p>office@pga.co.at</p>
            </div>
          </div>

          <p class="bold">Contact details for privacy issues:</p>
          <p class="mb-15">datenschutz@ibs-austria.com</p>

          <p class="bold">Data collection when using our website</p>
          <p>When you visit our website, no personal data is automatically collected from you.</p>
          <p class="mb-15">Neither the IP address of your device, nor the date and time of access, nor the Internet
            browser used as well as your operating system, nor your click behavior on the website, nor your Internet
            provider as well as the Internet page, are recorded or even stored.</p>

          <p>Access to the website is only possible for the authorized user with personal access data (user name,
            password). The access data will be provided to the active employee of IBS/PGA or the selected partners by
            PGA.</p>
          <p>The use of the website is exclusively intended for active employees of IBS/PGA or selected partners.</p>
          <p class="mb-15">Passing on personal access data to third parties is not permitted.</p>

          <p class="fit">Prior to the first use of the website or the <span class="bold">starchPERFORMER Savings
            Calculator,</span> personal data
            of the user will be requested by the PGA.</p>
          <p class="bold mr-1"></p>

          <p>Specifically, this is the following data: Name, Phone Number, Email Address.</p>
          <p>The Personal Data is stored by the PGA and is processed when the Saving Calculator is used, when the
            Savings
            Calculation Report is generated.</p>
          <p>More precisely, the data is shown on the report as the contact details of the person who created the
            report.</p>
          <p>The personal data of each authorized user is accessible to each authorized user of the website.</p>
          <p>Your personal data will be passed on by us in the course of pre-contractual communication.</p>
          <p>The data is passed on by sharing the calculation report with the customer and his employees for whom it was
            created, as well as IBS/PGA internally to employees who use the calculation report as a technical and
            commercial planning basis.</p>

          <p>We will store the User's personal data stored by PGA in the <span class="bold">starchPERFORMER Savings
            Calculator</span> and made
            available to Users for the duration of the period of use of the respective User, but not longer than six
            months after the termination of the User's service or business relationship.</p>

          <p class="mb-15">The personal data on the created calculation reports will be preserved even after the
            termination of the service or business relationship.</p>

          <p class="bold">Your rights</p>
          <p>You have the following rights under the GDPR if the legal requirements are met: the right to information,
            rectification, erasure, restriction of processing, data portability, objection to processing. You have the
            right to complain to a supervisory authority if you believe that the processing of your personal data is not
            carried out lawfully. For Austria: Austrian Data Protection Authority, Barichgasse 40-42, 1030 Vienna,
            e-mail:
            dsb@dsb.gv.at</p>
        </div>

        <div>
          <p class="header mb-15">/Information gemäß Art 13 DSGVO</p>
          <p class="mb-15">Name und Kontaktdaten des Verantwortlichen:</p>

          <p>PGA Anlagenbau Ges.m.b.H.</p>
          <p>Gewerbeweg 3,</p>
          <p>9241 Wernberg,</p>
          <p class="mb-15">Österreich</p>

          <div class="mb-15">
            <div class="flex">
              <p class="mr-1 bold">Tel.:</p>
              <p>+43 (0) 4252 33030</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">Fax:</p>
              <p>+43 (0) 4252 33030-720</p>
            </div>
            <div class="flex">
              <p class="mr-1 bold">E-mail:</p>
              <p>office@pga.co.at</p>
            </div>
          </div>

          <p class="bold">Kontaktdaten für Datenschutzanfragen:</p>
          <p class="mb-15">datenschutz@ibs-austria.com</p>

          <p class="bold">Datenerhebung bei der Nutzung unserer Webseite</p>
          <p>Wenn Sie unsere Website besuchen, werden keine persönlichen Daten von Ihnen automatisch erfasst.</p>
          <p class="mb-15">Es werden weder die IP-Adresse Ihres Geräts noch Datum und Uhrzeit des Abrufs, noch der
            verwendeten Internetbrowser sowie Ihr Betriebssystem, noch Ihr Klickverhalten auf der Website, noch Ihr
            Internetprovider sowie die Internetseite, erfasst oder gar gespeichert.</p>

          <p>Der Zugang zur Webseite ist dem autorisierten Benutzer nur mit persönlichen Zugangsdaten (Benutzername,
            Passwort) möglich. Die Zugangsdaten werden dem aktiven Mitarbeiter der IBS/PGA oder den ausgewählten
            Partnern durch die PGA übermittelt.</p>
          <p>Die Benutzung der Webseite ist ausschließlich für aktive Mitarbeiter der IBS/PGA oder ausgewählten Partnern
            bestimmt.</p>
          <p>Eine Weitergabe der persönlichen Zugangsdaten an dritte ist nicht zulässig.</p>


          <p>Vor der ersten Verwendung der Website bzw. des <span class="bold">starchPERFORMER Savings Calculator</span>
            werden
            von der PGA persönliche Daten des Benutzers erhoben.</p>

          <p>Konkret handelt es sich um die folgenden Daten: Name, Telefonnummer, Emailadresse.</p>
          <p>Die Persönlichen Daten werden durch die PGA gespeichert und werden bei der Benutzung des Saving
            Calculators, bei der Erstellung des Ersparnis Berechnungsberichts verarbeitet.</p>
          <p>Konkret werden die Daten am Bericht als Kontaktdaten des Berichterstellers angeführt.</p>
          <p>Die persönlichen Daten jedes autorisierten Benutzers sind jedem autorisierten Benutzer der Webseite
            zugänglich.</p>
          <p>Ihre personenbezogenen Daten werden von uns im Zuge der vorvertraglichen Kommunikation weitergegeben.</p>
          <p>Die Weitergabe der Daten erfolgt über das Teilen des Berechnungsberichts mit dem Kunden und dessen
            Mitarbeitern, für welchen dieser erstellt worden ist sowie IBS/PGA intern an Mitarbeiter, welche den
            Berechnungsbericht als technische und kaufmännische Planungsgrundlage verwenden.</p>

          <p>Wir werden die persönlichen Daten des Benutzers, welche von der PGA im <span> starchPERFORMER Savings
            Calculator</span> gespeichert und Benutzern zugänglich gemacht werden, für die Dauer des Nutzungszeitraumes
            des
            jeweiligen Benutzers, maximal jedoch bis sechs Monate nach Beendigung des Dienst- oder
            Geschäftsverhältnisses des Benutzers, speichern.</p>
          <p class="mb-15">Die persönlichen Daten auf den erstellten Berechnungsberichten bleiben auch nach Beendigung
            des Dienst- oder Geschäftsverhältnisses erhalten. </p>

          <p class="bold">Ihre Rechte</p>
          <p>Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte gemäß DSGVO zu: Recht auf
            Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit, Widerspruch gegen
            die Verarbeitung. Sie haben das Recht, sich bei einer Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht
            sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt. Für Österreich:
            Österreichische Datenschutzbehörde, Barichgasse 40-42, 1030 Wien, E-Mail: dsb@dsb.gv.at</p>
        </div>

      </div>
    </template>
  </cr-page>
</template>

<script>
import CrPage from "@/components/CrPage";

export default {
  name: "CrDataProtection",
  components: {CrPage},
  methods: {
    /**
     * Open page
     */
    open() {
      this.$refs.page.open();
    },
  }
}
</script>

<style scoped>
.header {
  font-weight: bold;
  color: #cc00cc;
}

.mb-15 {
  margin-bottom: 15px;
}

.flex {
  display: flex;
}

.mr-1 {
  margin-right: 4px;
}

.bold {
  font-weight: bold;
}

.size {
  font-size: 12px;
  line-height: 1.5;
}

.fit {
  min-width: fit-content;
  margin-right: 3px;
}
</style>