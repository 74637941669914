<template>
  <div class="highlighted-title">{{ title }}</div>
</template>

<style scoped lang="scss">
.highlighted-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
  letter-spacing: -0.55px;
  color: #FF8F00;
}
</style>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    }
  },
}
</script>