<template>
  <div class="info-message"
       :class="type"
       :id="id">
    {{ text }}
  </div>
</template>

<style scoped lang="scss">
.info-message {
  font-size: 14px;
  padding: 10px 15px;
  margin: 0 0 15px;
  //border-radius: 6px;

  &.restriction {
    background-color: #3e8ed0;
    color: #fff;
  }

  &.error {
    background-color: #f14668;
    color: #fff;
  }
}
</style>

<script>
export default {
  components: {},
  props: {
    text: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: false,
      default: '',
    },

    id: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>