<template>
  <div class="input-wrapper"
       :class="{'has-currency': currency}">
    <input :type="type"
           v-model="selected"
           @focus="focus"
           @blur="blur">

    <div v-if="currency" class="currency">
      {{ currency }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.input-wrapper {
  position: relative;
  margin-bottom: 10px;

  &.error {
    input {
      border: 1px solid red;
      color: red;
      background-color: #ffe2e8;
    }
  }

  &.has-currency {
    input {
      padding-right: 60px;
    }
  }

  input {
    font-family: 'Inter', sans-serif;
    position: relative;
    height: 50px;
    background: rgb(149 161 167 / 5%);
    border: 1px solid #CFD8DC;
    border-radius: 6px;
    width: 100%;
    padding: 5px 12px;
    font-size: 15px;
  }

  .currency {
    position: absolute;
    right: 15px;
    top: 17px;
    font-size: 15px;
    text-align: right;
  }
}
</style>

<script>
export default {
  components: {},
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    modelValue: {
      default: null,
    },
    currency: {
      type: String,
      required: false,
      default: '',
    }
  },

  emits: ['update:modelValue', 'focus', 'blur'],

  data() {
    return {
      selected: this.modelValue ? this.modelValue : null
    }
  },

  watch: {
    selected(value) {
      this.$emit('update:modelValue', value);
    },
  },

  methods: {
    focus() {
      this.$emit('focus');
    },

    blur() {
      this.$emit('blur');
    }
  },
}
</script>